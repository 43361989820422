import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { WebsocketserviceService } from '../../../services/websocketservice.service';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Component({
  selector: 'app-instructor-dashboard-main',
  templateUrl: './instructor-dashboard-main.component.html',
  styleUrls: ['./instructor-dashboard-main.component.scss']
})
export class InstructorDashboardMainComponent implements OnInit {

    public messages:any[] = [];
    private serverUrl = 'https://scribar.herokuapp.com/socket';
  private stompClient;

   public isOnline = false;

  constructor(public snackBar: MatSnackBar, private router: Router, private http: HttpClient,public websocketserviceService: WebsocketserviceService, public authService: AuthService) {

  }

  ngOnInit() {
  }

  setStatus(status){


        this.http.get('https://scribar.herokuapp.com/api/dashboard/update/instructor/status?isOnline=' + status + '&instructor_id=' + this.authService.userProfile['i']['instructor_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                    console.log(data);

                                      this.isOnline = status;

                                        if(this.isOnline){

                                           this.connectToServer();

                                        }else{

                                            this.disconnectFromServer();

                                        }

                                    },
                                    (error) => {

                                        console.log(error);


                                        });


  }

  public connectToServer(){

        let ws = new SockJS(this.serverUrl);

        this.stompClient = Stomp.over(ws);
        let that = this;

        that.stompClient.connect({}, (frame)=> {

            console.log("connected!!");

          that.stompClient.subscribe("/dispatcher/" + this.authService.userProfile['i']['instructor_id'], (message) => {
            console.log(message);

            if(message.body) {

              console.log(message.body);

              message.body = message.body.replace(/\\n/g, "\\n")
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, "\\&")
                .replace(/\\r/g, "\\r")
                .replace(/\\t/g, "\\t")
                .replace(/\\b/g, "\\b")
                .replace(/\\f/g, "\\f");
              // remove non-printable and other non-valid JSON chars
              message.body = message.body.replace(/[\u0000-\u0019]+/g,"");

              var obj = JSON.parse(message.body.replace(/\n|\r/g, ""));


              if(obj['type'] === 'NEW_REQUEST'){
                that.messages.push(obj);
                that.snackBar.open('Incoming Request. ', '',{
                  duration: 1500
                });
              }

              if(obj['type'] === 'REQUEST_FILLED'){
                that.removeRequest(obj['request_id']);
              }


              return;

            }

              /*
                message.body = message.body.replace(/\\n/g, "\\n")
                               .replace(/\\'/g, "\\'")
                               .replace(/\\"/g, '\\"')
                               .replace(/\\&/g, "\\&")
                               .replace(/\\r/g, "\\r")
                               .replace(/\\t/g, "\\t")
                               .replace(/\\b/g, "\\b")
                               .replace(/\\f/g, "\\f");
                // remove non-printable and other non-valid JSON chars
                message.body = message.body.replace(/[\u0000-\u0019]+/g,"");


              var obj = JSON.parse(message.body.replace(/\n|\r/g, ""));

              if(obj['type'] === 'NEW_REQUEST'){
                that.messages.push(obj);
                  that.snackBar.open('Incoming Request. ', '',{
                                                    duration: 1500
                 });
              }

              if(obj['type'] === 'REQUEST_FILLED'){
                that.removeRequest(obj['request_id']);
              }

*/

          });

        });



  }

   public respondToRequest(request_id){



        this.http.get('https://scribar.herokuapp.com/api/matching/respond/request?request_id=' + request_id + '&instructor_id=' + this.authService.userProfile['i']['instructor_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                    console.log(data);

                                    this.router.navigate(['/d/i/live']);

                                    },
                                    (error) => {

                                        this.removeRequest(request_id);

                                        console.log(error);

                                         if(error.status === 409){

                                            this.snackBar.open('Sorry. This request has already been fulfilled. ', '',{
                                                    duration: 1500
                                            });

                                         }else{

                                            this.snackBar.open('Error accepting request. Please try again', '',{
                                                    duration: 1500
                                            });

                                         }


                                        });

   }

   public removeRequest(request_id){


        console.log("Removing request " + request_id);
        console.log(this.messages);


        for(var i = 0; i < this.messages.length; i++){

            console.log(this.messages[i]);

            if(this.messages[i].request_id == request_id){

                this.messages.splice(i, 1);

            }

        }

   }


   public disconnectFromServer(){
            this.stompClient.disconnect();

    }

    public sendMessage(message){

            this.stompClient.send("/app/respond" , {}, message);

    }





}
