import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {ConfirmDialog} from '../confirm-dialog/confirm.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-group',
  templateUrl: './company-dashboard-view-group.component.html',
  styleUrls: ['./company-dashboard-view-group.component.scss']
})


//Page to view a group and all its profiles
export class CompanyDashboardViewGroupComponent{

    public group:any;
    public profiles;
    public search_string: string = "";
    public search_term: string = "";


  constructor(public authService: AuthService, public dialog: MatDialog, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {
    console.log(this.route.snapshot.params.id);


    //Check for group id in url params
    if("id" in this.route.snapshot.params){

                //Get group from server
              this.http.get('https://scribar.herokuapp.com/api/profile/get/group?group_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting group by id " + this.route.snapshot.params.id + "...");
                                        this.group = data;
                                        console.log(this.group);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

//Get all profiles from group from server
          this.http.get('https://scribar.herokuapp.com/api/profile/get/profiles/by/group?group_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting profiles by group_id " + this.route.snapshot.params.id + "...");
                                        this.profiles = data;
                                        console.log(this.profiles);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }else{

        //If no id in params navigate away from page
        this.router.navigate(['/d/organization']);

    }



    }

//Navigate to edit group
editGroup(){
    this.router.navigate(['/d/organization/edit/group', this.group['profile_group_id']]);
}
//Nagivate to add new profile
addNewProfile(){
    this.router.navigate(['/d/organization/create/profile', this.group['profile_group_id']]);
}

//Navigate to open profile
 openProfile(id){
    this.router.navigate(['/d/organization/view/profile', id]);
  }


 removeProfile(id){

let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(confirm => {

        if(confirm){

            this.http.get('https://scribar.herokuapp.com/api/profile/remove/profile?profile_id=' + id, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {


                                        //Get all profiles from group from server
          this.http.get('https://scribar.herokuapp.com/api/profile/get/profiles/by/group?group_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting profiles by group_id " + this.route.snapshot.params.id + "...");
                                        this.profiles = data;
                                        console.log(this.profiles);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });


                                    },
                                    (error) => {


                                        });


        }else{

        }

    });


  }

}
