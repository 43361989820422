import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { fuseAnimations } from '@fuse/animations';

/*
*
* Author: Saboor Salaam
*
*/


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations : fuseAnimations
})

//Page for beggining a password reset
export class ForgotPasswordComponent implements OnInit {

   //Model for user input
    user = {
        email: ''
    };

    //Show error message
    hasError = false;

    //Show success message
    hasSuccess = false;

    submitButtonText = "Submit";

  constructor(public authService: AuthService, private http: HttpClient, private router: Router) {

    }


    //Init password reset
    resetPassword(){

    this.submitButtonText = "Loading...";

          //Send password reset request
           this.http.get('https://scribar.herokuapp.com/api/account/reset/password?username=' + this.user.email, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        //Show success prompt
                                        console.log(data);
                                        this.hasSuccess = true;
                                        this.hasError = false;

                                        this.submitButtonText = "Submit";


                                    },
                                    (error) => {

                                        //Show error prompt
                                        console.log(error);
                                        this.hasError = true;
                                        this.hasSuccess = false;
                                        this.submitButtonText = "Submit";

                                        });

    }

  ngOnInit() {
  }

}
