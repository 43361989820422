import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-company-dashboard-select-plan',
  templateUrl: './company-dashboard-select-plan.component.html',
  styleUrls: ['./company-dashboard-select-plan.component.scss']
})
export class CompanyDashboardSelectPlanComponent implements AfterViewInit, OnDestroy {


  @ViewChild('cardInfo') cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  sources:any = [];

  plans:any[] = [];

  history:any[] = [];

  displayedColumns: string[] = ['date', 'description', 'status'];

  loadingData = true;

  selected_plan = "";

  constructor( public authService: AuthService, private http: HttpClient, private cd: ChangeDetectorRef) {
    this.getPlans();
    this.getSources();
    this.getInvoiceHistory();
  }

  getPlans() {

    this.http.get('https://scribar.herokuapp.com/api/payment/get/plans', this.authService.httpOptions)
      .subscribe(
        (data) => {

          this.plans = <any[]> data;
          console.log(this.plans);



          this.loadingData = false;
        },
        (error) => {

          console.log(error);

          this.loadingData = true;


        });

  }

  choosePlan(){

    console.log(this.authService.userProfile['c']);
    this.http.get('https://scribar.herokuapp.com/api/payment/create/subscription?account_id=' + this.authService.userProfile['c']['account_id'] + "&plan="  + this.selected_plan,this.authService.httpOptions)
      .subscribe(
        (data) => {

        },
        (error) => {

          console.log(error);

        });


  }

  getSources(){


//Get existing payments methods for user from server
    this.http.get('https://scribar.herokuapp.com/api/payment/get/sources?account_id=' + this.authService.userProfile['c']['account_id'] , this.authService.httpOptions)
      .subscribe(
        (data) => {
          console.log(data);
          this.sources = JSON.parse(data['data'])['data'];
          console.log(this.sources);
        },
        (error) => {
          console.log(error);

        });

  }

  removeSource(card_id){

//Get existing payments methods for user from server
    this.http.get('https://scribar.herokuapp.com/api/payment/remove/source?account_id=' + this.authService.userProfile['c']['account_id'] + '&card_id=' + card_id  , this.authService.httpOptions)
      .subscribe(
        (data) => {
          console.log(data);
          this.getSources();
          console.log(this.sources);
        },
        (error) => {
          console.log(error);

        });

  }


  setDefaultSource(card_id){


//Get existing payments methods for user from server
    this.http.get('https://scribar.herokuapp.com/api/payment/set/default/source?account_id=' + this.authService.userProfile['c']['account_id'] + '&card_id=' + card_id  , this.authService.httpOptions)
      .subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);

        });

  }


  getInvoiceHistory(){

//Get existing payments methods for user from server
    this.http.get('https://scribar.herokuapp.com/api/payment/get/invoice/history?account_id=' + this.authService.userProfile['c']['account_id'], this.authService.httpOptions)
      .subscribe(
        (data) => {
          console.log(data);
          this.history = <any[]>data;
        },
        (error) => {
          console.log(error);

        });

  }
  //Angular lifecycle method called after view is initialized
  ngAfterViewInit() {

    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);



  }
  //Remove listeners when component is destroyed
  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  //On user submits new payment info
  async onSubmit(form: NgForm) {
        const { token, error } = await stripe.createToken(this.card);

        if (error) {

          console.log('Something is wrong:', error);

        } else {

          console.log('Success!', token);

          //Send new payment info to server


           this.http.get('https://scribar.herokuapp.com/api/payment/add/source?account_id=1&token=' + token['id'] + '&account_id=' + this.authService.userProfile['c']['account_id'] , this.authService.httpOptions)
                                        .subscribe(
                                        (data) => {
                                            console.log(data);
                                            alert("Card added successfully");

              this.getSources();

                                        },
                                        (error) => {
                                            console.log(error);

           });



        }

  }

}
