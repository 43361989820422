import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import {MatTableDataSource} from '@angular/material';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-requests',
  templateUrl: './company-dashboard-requests.component.html',
  styleUrls: ['./company-dashboard-requests.component.scss']
})


//Page to view registration requests
export class CompanyDashboardRequestsComponent implements OnInit {

    requests: any[];

    constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService) {

    //Get all registration requests from server
     this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {
                                        console.log("Getting requests...");
                                        console.log(data);
                                        this.requests = <any[]>data;
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }

  ngOnInit() {
  }

//Function to respond to a request
  respondToRequest(user_id, response){

    //Send response to server
     this.http.get('https://scribar.herokuapp.com/api/dashboard/respond/request?uid=' + user_id + '&response=' + response, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {
                                        console.log(data);

//Update list of requests
  this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {
                                        console.log("Getting requests...  " + 'https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id']);
                                        console.log(data);
                                        this.requests = <any[]>data;
                                    },
                                    (error) => {

                                        console.log(error);


                                        });


                                    },
                                    (error) => {

                                        console.log(error);

                                        });


    }

}
