import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-company-view-task-performance-record',
  templateUrl: './company-view-task-performance-record.component.html',
  styleUrls: ['./company-view-task-performance-record.component.scss']
})
export class CompanyViewTaskPerformanceRecordComponent{


  public task_record:any;

  constructor(private authService: AuthService, private domSanitizer: DomSanitizer, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {

        console.log("Route params!!!");
        console.log(this.route.snapshot.params);


        if("performance_profile_id" in this.route.snapshot.params && "task_id" in this.route.snapshot.params){

            this.http.get('https://scribar.herokuapp.com/api/performance/generate/task/report?performance_profile_id='
 + this.route.snapshot.params.performance_profile_id + '&task_id=' + this.route.snapshot.params.task_id, this.authService.httpOptions)
                                              .subscribe(
                                              (data) => {

                                                  this.task_record = data;
                                                  console.log(this.task_record);
                                              },
                                              (error) => {

                                                  console.log(error);

                                                  });

        }

  }


 openRecord(id){
    this.router.navigate(['d/organization/view/performance/record', id]);
  }

}
