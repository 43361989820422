import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-create-task',
  templateUrl: './company-dashboard-create-task.component.html',
  styleUrls: ['./company-dashboard-create-task.component.scss']
})


//Page to create a new task
export class CompanyDashboardCreateTaskComponent implements OnInit {

        task = {task_name: "", task_description: "", workflow_id: "", expected_time:1.0 }
        postButtonText = "Save";
        hasError = false;
        errorText = "Error creating task!";

        constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {

            //Get parent workflow id from url params
            if("id" in this.route.snapshot.params){
                this.task.workflow_id = this.route.snapshot.params.id
            }else{

                            this.router.navigate(['/d/organization']);

            }

        }

  ngOnInit() {
  }



//Save task
postTask(){


   this.postButtonText = "Loading...";

    //Send task data to server in post request
    this.http.post('https://scribar.herokuapp.com/api/dashboard/create/task', this.task, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        //If success navigate back to parent workflow
                                        console.log(data);
                                        this.postButtonText = "Save";
                                        this.router.navigate(['/d/organization/view/workflow', this.route.snapshot.params.id]);
                                    },
                                    (error) => {

                                        //If error show appropriate prompt
                                        console.log(error);
                                        this.postButtonText = "Save";
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "A task with this name already exists!";
                                        }else{
                                        this.errorText = "Error creating task."
                                        }

   });

  }

}
