import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
              public authService: AuthService) { }

  canActivate() {

    if  ( this.authService.isLoggedIn() ) {
      return true;
    }

    this.router.navigate(['/login']);

    return false;
  }

}
