import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-edit-profile',
  templateUrl: './company-dashboard-edit-profile.component.html',
  styleUrls: ['./company-dashboard-edit-profile.component.scss']
})


//Page to edit a profile
export class CompanyDashboardEditProfileComponent implements OnInit {

        public groups;

        public constants;

        public profile;

        //public industry_type;

        postButtonText = "Save";
    hasError = false;
    errorText = "Error creating group";


 constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {

        //Get all group from parent company
         this.http.get('https://scribar.herokuapp.com/api/profile/get/groups/by/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting groups...");
                                        console.log(data);
                                        this.groups = data;
                                        console.log(this.groups);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    //Get all constants for dropdowns
    this.http.get('https://scribar.herokuapp.com/api/profile/get/all/constants', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all constants...");
                                        this.constants = data;
                                        console.log(this.constants);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });

  //Get profile data from server
  this.http.get('https://scribar.herokuapp.com/api/profile/get/profile?profile_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting group by id " + this.route.snapshot.params.id + "...");
                                        this.profile = data;
                                        console.log(this.profile);
                                    },
                                    (error) => {
                                        console.log(error);
                                        });

  }

  ngOnInit() {
  }


  //Save updated profile
  saveProfile(){
console.log(this.profile);
   this.postButtonText = "Loading...";


    //Send updated data to server
    this.http.post('https://scribar.herokuapp.com/api/profile/update/profile', this.profile, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {


                                        //If success navigate to groups page
                                        console.log(data);
                                        this.postButtonText = "Save";
                                        this.router.navigate(['/d/organization/groups']);

                                    },
                                    (error) => {

                                        console.log(error);
   console.log(error);
                                        console.log(error);
                                        this.postButtonText = "Save";

                                  //if error show prompts to user
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "A group with this name already exists!";
                                        }else{
                                        this.errorText = "Error creating workflow."
                                        }



                                        });



}

}
