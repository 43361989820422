import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'any'
})
export class AnyPipePipe implements PipeTransform {

    transform(items: any[], attrs: any[], searchText: string): any[] {

    console.log(items);

    if(!items) return [];
    if(!searchText) return items;
    if(!attrs) return items;

    console.log(attrs);

    return items.filter( it => {
        
        for(var i = 0; i < attrs.length;i++){

            console.log("Actual value: " + it[attrs[i]].toLowerCase());
            console.log("Query: " + searchText);
            console.log(it[attrs[i]].toLowerCase().includes(searchText));

            if(it[attrs[i]].toLowerCase().includes(searchText.toLowerCase())){
                return true;
            }
            
        }
        
                    return false;

    });
  }

}
