import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ENTER, COMMA} from '@angular/cdk/keycodes';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'help-dialog',
  templateUrl: './help.html',
  styleUrls: ['./help.css']
})
export class HelpDialog {

    public title = "";
    public content = "";

    constructor(
   
    public dialogRef: MatDialogRef<HelpDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
            
            this.title = data['title'];
            this.content = data['content'];
            
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
