import {Component, OnInit, ElementRef, ViewChild, ViewChildren, AfterViewInit, QueryList, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import { AuthService } from '../../../services/auth.service';
import { TwilioService } from '../../../services/twilio.service';

import { ActivatedRoute } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {VgAPI} from 'videogular2/core';
import {Subscription} from 'rxjs';

import {formatDate } from '@angular/common';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-devices',
  templateUrl: './company-dashboard-view-devices.component.html',
  styleUrls: ['./company-dashboard-view-devices.component.scss']
})


//Page to view stream and user interface for each device
export class CompanyDashboardViewDevicesComponent implements OnInit, AfterViewInit, OnDestroy{

  message: string;
  accessToken: string;
  roomName: string;
  username: string;
  pdfUrl = null;
  today = new Date();
  jstoday = '';

  @ViewChildren('localVideo') localVideos: QueryList<ElementRef>;
  @ViewChildren('remoteVideo') remoteVideos: QueryList<ElementRef>;


    public devices: any[];
    public constants: any;
    public ready = false;
    public step_text = 'Elevate rectus muscle';
    public index_1 = 10;
    public index_2 = 1;
    public index_3 = 2;
    public showText = true;

    public locationSubscription: Subscription;

    public editor_config = {
    editable: true,
    spellcheck: true,
    height: '500',
    minHeight: '500'
    };


  public messages: any[] = [];
  public screenshots: any[] = [];
  private serverUrl = 'https://scribar.herokuapp.com/socket';
  private stompClient;




  constructor(private twilioService: TwilioService, private domSanitizer: DomSanitizer, public authService: AuthService, private http: HttpClient, private router: Router) {

    this.authService.isDemo = true;

  this.twilioService.msgSubject.subscribe(r => {
    this.message = r;
  });


//https://scribar.herokuapp.com
 this.http.get('https://scribar.herokuapp.com/api/device/get/devices', this.authService.httpOptions) //+ this.authService.userProfile['c']['company_id']
    .subscribe(
      (data) => {

        this.devices = <any[]>data;

        console.log(this.devices);

          console.log(this.localVideos);
        console.log(this.remoteVideos);

      },
      (error) => {

        console.log(error);


      });




  /*
//Get all constants from server i.e. skill_level
this.http.get('https://scribar.herokuapp.com/api/profile/get/all/constants', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all constants...");
                                        this.constants = data;
                                        console.log(this.constants);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });



//Get all devices for a company
 this.http.get('https://scribar.herokuapp.com/api/device/get/by/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.devices = <any[]>data;

                                        for(var i = 0; i < this.devices.length; i++){

                                        this.devices[i]['note'] = "";
                                        this.devices[i]['isPass'] = false;
                                        this.devices[i]['instructor'] = "";

                                        //If user performing task on this device is a novice or intermediate
                                         if(this.devices[i]['skill_level'] < 3){

                                                //Set recurring function to poll server at interval
                                                setInterval(() => {

                                                            //Get updated device data including current step, task, user data etc...
                                                            this.http.get('https://scribar.herokuapp.com/api/device/get/by/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                                                                               .subscribe(
                                                                                               (data) => {


                                                                                                   for(var i = 0; i < (<any[]>data).length; i++){

                                                                                                      console.log(this.devices[i]);

                                                                                                       //If active status of device has changed
                                                                                                      if(this.devices[i]['isActive'] !== data[i]['isActive']){

                                                                                                       this.devices[i] = data[i];
                                                                                                       this.devices[i]['note'] = "";
                                                                                                       this.devices[i]['isPass'] = false;
                                                                                                       this.devices[i]['instructor'] = "";


                                                                                                       //If step has changes
                                                                                                      }else if(this.devices[i]['step']['step_id'] !== data[i]['step']['step_id']){
                                                                                                           this.devices[i]['step'] = data[i]['step'];
                                                                                                           this.devices[i]['icon'] = data[i]['icon'];
                                                                                                      }

                                                                                                   }

                                                                                               },
                                                                                               (error) => {

                                                                                                   console.log(error);


                                                                                                   });


                                                           }, 1500);


                                         }else {



                                         }

                                        }

                                    },
                                    (error) => {

                                        console.log(error);


                                        });

*/
    }


  public connectToServer(){

    const ws = new SockJS(this.serverUrl);

    this.stompClient = Stomp.over(ws);
    const that = this;

    that.stompClient.connect({}, (frame) => {

      console.log('connected!!');

      that.stompClient.subscribe('/dispatcher/1', (message) => {
        console.log(message);

        if (message.body) {

          console.log(message.body);

          message.body = message.body.replace(/\\n/g, '\\n')
            .replace(/\\'/g, '\\\'')
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, '\\&')
            .replace(/\\r/g, '\\r')
            .replace(/\\t/g, '\\t')
            .replace(/\\b/g, '\\b')
            .replace(/\\f/g, '\\f');
          // remove non-printable and other non-valid JSON chars
          message.body = message.body.replace(/[\u0000-\u0019]+/g, '');

          const obj = JSON.parse(message.body.replace(/\n|\r/g, ''));

          if (obj['type'] === 'NEW_SCREENSHOT'){
            console.log(obj);
            that.screenshots.push(obj);
            console.log(that.screenshots);
          }


          return;

        }

      });

    });



  }

  openImage(url){
    this.router.navigate(['edit/photo', url]);
  }

  ngOnInit() {
   // this.twilioService.localVideo = this.localVideo;
   // this.twilioService.remoteVideo = this.remoteVideo;
  }

  ngAfterViewInit() {

    this.localVideos.changes.subscribe(() => console.log(this.localVideos));

    this.remoteVideos.changes.subscribe(() => console.log(this.remoteVideos));


  }

  log(message) {
    this.message = message;
  }

  uploading = false;

  //Listener for new file selected for workflow cover
  onFileChange(event) {

    //Get list of selected files
    const fileList = event.target.files;
    const observablesList = [];

    this.uploading = true;

    if (fileList.length > 0) {

      //Get first file from list
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      console.log(fileList);

      //Upload file to server
      observablesList.push(this.http.post('https://scribar.herokuapp.com/api/file/upload/image', formData, {
        headers: new HttpHeaders({'Authorization': this.authService.idToken})
      }));

    }


    //When upload is complete
    Observable.forkJoin(observablesList).subscribe(results => {
      console.log(results);
      alert('All uploaded!');
      this.uploading = false;

      this.pdfUrl = results[0].file_path;

    });

  }

  disconnect(roomObj) {

    if (roomObj && roomObj !== null) {
      roomObj.disconnect();
      roomObj = null;
    }

  }

  connect(): void {

    //this.connectToServer();

    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');


    for (let i = 0; i < this.devices.length; i++){

      this.devices[i]['note'] = '';
      this.devices[i]['isPass'] = false;
      this.devices[i]['instructor'] = '';
      this.devices[i]['roomObj'] = {};

      const that = this;

      (function(index) {

        //Need eclipe controller just for getting instructors token!!!
        that.http.get('https://scribar.herokuapp.com/api/device/request/twillio/token/instructor?room=' + that.devices[index]['room'], that.authService.httpOptions)
          .subscribe(
            (data) => {

              console.log('Getting token...');
              console.log( data['token']);

              that.twilioService.connectToRoom(data['token'], { name: that.devices[index]['room'], audio: true, video: { width: 240 } }, that.localVideos.toArray()[index], that.remoteVideos.toArray()[index], that.devices[index]['roomObj'] );


            },
            (error) => {

              console.log(error);

            });


      })(i);

      /*
      that.http.get('https://scribar.herokuapp.com/api/device/get/location?unique_device_id=u28uw2uej2eji2e', that.authService.httpOptions)
        .subscribe(
          (data) => {

            console.log('Getting location...');
            console.log(data);

            for (let i = 0; i < that.devices.length; i++) {
              that.devices[i]['location'] = data;
            }

          },
          (error) => {

            console.log(error);

          });

      this.locationSubscription = Observable.interval(1000 * 60).subscribe(x => {
        //Need eclipe controller just for getting instructors token!!!
        that.http.get('https://scribar.herokuapp.com/api/device/get/location?unique_device_id=u28uw2uej2eji2e', that.authService.httpOptions)
          .subscribe(
            (data) => {

              console.log('Getting location...');
              console.log(data);

              for (let i = 0; i < that.devices.length; i++) {
              that.devices[i]['location'] = data;
              }

            },
            (error) => {

              console.log(error);

            });
      });

      */


      //If user performing task on this device is a novice or intermediate
      if (this.devices[i]['skill_level'] < 3){

        /*
        //Set recurring function to poll server at interval
        setInterval(() => {

          //Get updated device data including current step, task, user data etc...
          this.http.get('https://scribar.herokuapp.com/api/device/get/by/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
            .subscribe(
              (data) => {


                for(var i = 0; i < (<any[]>data).length; i++){

                  console.log(this.devices[i]);

                  //If active status of device has changed
                  if(this.devices[i]['isActive'] !== data[i]['isActive']){

                    this.devices[i] = data[i];
                    this.devices[i]['note'] = "";
                    this.devices[i]['isPass'] = false;
                    this.devices[i]['instructor'] = "";


                    //If step has changes
                  }else if(this.devices[i]['step']['step_id'] !== data[i]['step']['step_id']){
                    this.devices[i]['step'] = data[i]['step'];
                    this.devices[i]['icon'] = data[i]['icon'];
                  }

                }

              },
              (error) => {

                console.log(error);


              });


        }, 2000);

        */


      }else {



      }

    }

    //this.twilioService.connectToRoom(this.accessToken, { name: 'cammrad', audio: true, video: { width: 240 } });


    /*
        let storage = JSON.parse(localStorage.getItem('token') || '{}');
        let date = Date.now();
        if (!this.roomName || !this.username) { this.message = "enter username and room name."; return;}
        if (storage['token'] && storage['created_at'] + 3600000 > date) {
          this.accessToken = storage['token'];
          this.twilioService.connectToRoom("", { name: "cammrad", audio: true, video: { width: 240 } })
          return;
        }
        this.twilioService.getToken(this.username).subscribe(d => {
          this.accessToken = d['token'];
          localStorage.setItem('token', JSON.stringify({
            token: this.accessToken,
            created_at: date
          }));
          this.twilioService.connectToRoom(this.accessToken, { name: this.roomName, audio: true, video: { width: 240 } })
        },
          error => this.log(JSON.stringify(error)));

    */

  }

// Called by videoagular when video player is init
    onPlayerReady(api: VgAPI) {

            api.getDefaultMedia().subscriptions.canPlay.subscribe(
                () => {
                    // Seek to current in stream
                    // this.api.seekTime(100, true);
                    api.play();
                }
            );
    }



saveEvaluation(index, id, note, isPass, instructor){
    this.saveNote(index, id, note);
    this.saveIsPass(index, id, isPass);
    this.saveInstructor(index, id, instructor);
}


//Save not for performer
  saveInstructor(index, id, instructor){

          console.log(id);
            console.log(instructor);

          //Send note data to server
          const url = 'https://scribar.herokuapp.com/api/device/set/evaluator?performance_record_id=' + id + '&evaluator=' + instructor;

          this.http.get(url, this.authService.httpOptions)
                                                        .subscribe(
                                                            (data) => {
                                                               console.log('Saved instructor!!!');
                                                               console.log(data);
                                                            },
                                                            (error) => {
           });


  }

  //Save not for performer
  saveNote(index, id, note){

            console.log(id);
            console.log(note);

          //Send note data to server
          const url = 'https://scribar.herokuapp.com/api/device/save/note';

          this.http.post(url, {performance_record_id: id, note: note}, this.authService.httpOptions)
                                                        .subscribe(
                                                            (data) => {
                                                               console.log(data);
                                                               this.devices[index]['commentSent'] = true;
                                                            },
                                                            (error) => {
           });



  }


  //Save not for performer
  saveIsPass(index, id, isPass){

            console.log(id);
            console.log(isPass);

          //Send note data to server
          const url = 'https://scribar.herokuapp.com/api/device/set/pass?performance_record_id=' + id + '&isPass=' + isPass;

          this.http.get(url, this.authService.httpOptions)
                                                        .subscribe(
                                                            (data) => {
                                                               console.log('Saved pass!!!');
                                                               console.log(data);
                                                            },
                                                            (error) => {
           });

  }


  isAnyDeviceActive(){

   let isAnythingActive = false;

   for (let i = 0; i < this.devices.length; i++){
        if (this.devices[i]['isActive']){
            isAnythingActive = true;
        }
   }

   return isAnythingActive;

  }

  ngOnDestroy(): void {

    this.locationSubscription.unsubscribe();

  }


}
