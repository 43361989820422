import { Component, NgZone, OnInit, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ConditionsDialog} from '../conditions-dialog/conditions.component';
import {RoutesDialog} from '../routes-dialog/routes.component';



import * as mermaid from 'mermaid';

import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-task',
  templateUrl: './company-dashboard-view-task.component.html',
  styleUrls: ['./company-dashboard-view-task.component.css']
})


//Page to view task
export class CompanyDashboardViewTaskComponent{

    public task:any;
    public steps: any[];
    public listBoxers = [{name:"ali"}, {name:"tyson"}, {name:"layla"}, {name:"wedwd"}];
    public currentStep = 0;
    public fileList;
    public showConditionsModel = false;
    public showAddRouteModal = false;
    public showRoutesModel = false;
    public showAidsModel = false;
    public shownStepInModal:any;
    public uploading = false;
    public newConditionText = "";
    public newRouteText = "";
    public newRouteIndex = 1;
    public newStepText = "";
    public newStepTime = 0;
    public diagram;
    public newRoute:any;
    public myTab: number = 0;

    public currentPMStep = 0;


  constructor(public dialog: MatDialog, public location: Location, private ref: ChangeDetectorRef, private elRef:ElementRef, private ngZone: NgZone, private domSanitizer: DomSanitizer, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {
    //.log(this.route.snapshot.params.id);


    mermaid.mermaidAPI.initialize({startOnLoad:true});

   // console.log(mermaid.mermaidAPI);

    var graphDefinition = 'graph TB' +
'\n start["START TASK"] --> s1["Step 1:  Position patient & maintain proper airway. "]' +
'\n s1 ==>|Default|s2["Step 2: Ventilate the patient and then stop."]' +
'\n s2 ==>|Default|s3["Step 3: Select proper mask size."]' +
'\n s3 ==>|Default| s4c{"Step 4: Seal mask to face (3 Conditions)"}' +
'\n s4c -->|On: True, True, False | s3 ' +
'\n s4c -->|On: True, False, True| s3 ' +
'\n s4c -->|On: False, False, False| s6["Step 6: This is step 6"]' +
'\n s4c ==>|Default| s5c{"Step 5: This step 5 (2 Conditions)"}' +
'\n s5c -->|On: True, True | s7["Step 7: This is step 7"] ' +
'\n s5c -->|On: False, False| s8["Step 8: this step 8"] ' +
'\n s5c ==>|Default| s6 ' +
'\n s6 ==>|Default| s7 ' +
'\n s7 ==>|Default| s8 ' +
'\n s8 ==>|Default| s9["Step 9: this step 9"] ' +
'\n s9 --> en["END TASK"] ' +
'\n  style s4c fill:#ccf,stroke:#f66,stroke-width:2px,stroke-dasharray: 5, 5' +
'\n style start fill:#f9f,stroke:#333,stroke-width:4px ' +
'\n style en fill:#f9f,stroke:#333,stroke-width:4px ' +
'\n  style s5c fill:#e74c3c,stroke:#e74c3c,stroke-width:2px,stroke-dasharray: 5, 5;' +
'\n  click start callback "Tooltip for a callback"';

     if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
       this.router.navigate(['/d/admin']);
    }

    //console.log(this.authService.userProfile);


    if("workflow_id" in this.route.snapshot.params && "task_id" in this.route.snapshot.params){

              this.http.get('https://scribar.herokuapp.com/api/dashboard/get/task?task_id=' + this.route.snapshot.params.task_id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting task by id " + this.route.snapshot.params.task_id + "...");
                                        this.task = data;
                                        console.log(this.task);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

          this.http.get('https://scribar.herokuapp.com/api/dashboard/get/steps?task_id=' + this.route.snapshot.params.task_id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting steps from task by id " + this.route.snapshot.params.task_id + "...");

                                        this.steps = <any[]>data;

                                        this.http.post('https://scribar.herokuapp.com/api/flowchart/generate/markup',{task_id: this.route.snapshot.params.task_id, steps: this.steps}, this.authService.httpOptions).subscribe(data => {

                                                        var markup = data['markup'];

                                                        mermaid.mermaidAPI.render('id1', markup, (svg) => {
                                                                                      this.ngZone.run(() => {

                                                                                   this.diagram = this.domSanitizer.bypassSecurityTrustHtml(svg);

                                                                                     });
                                                                                });
                                                    });



                                        console.log(this.steps);


                                          for(var i = 0; i < this.steps.length; i++){

                                                console.log(this.steps[i]['icon']['file_path']);
                                                this.steps[i]['step']['img_src'] = this.steps[i]['icon']['file_path'];

                                           }


                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }else{

        this.router.navigate(['/d/organization']);

    }



    }


    toggleAidModal(step){
        this.showAidsModel = !this.showAidsModel;
        this.shownStepInModal = step;
        console.log(this.shownStepInModal);
    }

    toggleConditionsModal(step){

        console.log('Toggle conditions modal!!!');
        this.showConditionsModel = !this.showConditionsModel;
        this.shownStepInModal = step;
        console.log(this.shownStepInModal);
        console.log();
    }

    toggleRouteModal(step){
        this.showRoutesModel = !this.showRoutesModel;
        this.shownStepInModal = step;
        console.log(this.shownStepInModal);
    }

    toggleAddRouteModal(){

        this.newRoute['results'] = [];
        this.newRoute['condition_indexes'] = [];


        for(var i = 0; i < this.shownStepInModal.conditions.length; i++){
            this.newRoute.results.push('YES');
        }

         for(var i = 0; i < this.steps.length; i++){
            this.newRoute.condition_indexes.push(i);
        }

        console.log(this.newRoute);

        this.newRoute['step_index'] = 0;



        this.showConditionsModel = !this.showConditionsModel;
        this.showAddRouteModal = !this.showAddRouteModal;

    }

    setCurrentStep(i){
        this.currentStep = i;
        console.log(this.currentStep);
    }

     aidFileChange(event) {

        let observablesList = [];

        this.fileList = event.target.files;

        this.uploading = true;

console.log(this.fileList);

        for(var i = 0; i < this.fileList.length; i++) {

            //ACTUALLY LOOP THROUGH FILES
            let file: File = this.fileList[i];
            let formData:FormData = new FormData();
            formData.append('file', file, file.name);
            console.log(this.fileList);

            let workflow_id: string = this.route.snapshot.params.workflow_id+"";
            let company_id: string = this.authService.userProfile['c']['company_id'] +"";
            let task_id: string = this.route.snapshot.params.task_id + "";
            let step_id: string = this.currentStep + "";


            observablesList.push(this.http.post('https://scribar.herokuapp.com/api/file/upload/aid', formData, {
                            headers: new HttpHeaders({
                              'Authorization': this.authService.idToken,
                              'workflow_id':workflow_id,
                              'company_id': company_id,
                              'task_id':task_id,
                              'step_id': step_id
                            })
                        }));

        }


        Observable.forkJoin(observablesList).subscribe(results => {
            console.log(results);
            alert("All uploaded!");
            this.uploading = false;
        });


}

   iconFileChange(event) {

        let observablesList = [];

        this.fileList = event.target.files;

        this.uploading = true;

        if(this.fileList.length > 0) {

            //ACTUALLY LOOP THROUGH FILES
            let file: File = this.fileList[0];
            let formData:FormData = new FormData();
            formData.append('file', file, file.name);
            console.log(this.fileList);

            let workflow_id: string = this.route.snapshot.params.workflow_id+"";
            let company_id: string = this.authService.userProfile['c']['company_id'] +"";
            let task_id: string = this.route.snapshot.params.task_id + "";
            let step_id: string = this.currentStep + "";


            observablesList.push(this.http.post('https://scribar.herokuapp.com/api/file/upload/icon', formData, {
                            headers: new HttpHeaders({
                              'Authorization': this.authService.idToken,
                              'workflow_id':workflow_id,
                              'company_id': company_id,
                              'task_id':task_id,
                              'step_id': step_id
                            })
                        }));

        }

        Observable.forkJoin(observablesList).subscribe(results => {
            console.log(results);
            alert("All uploaded!");

            this.uploading = false;
        });


}



    //Listener for file selected event
   videoFileChange(event) {

        let observablesList = [];

        //Get list of uploaded files
        this.fileList = event.target.files;

        //this.uploading = true;

        if(this.fileList.length > 0) {

            let file: File = this.fileList[0];

            if(this.ValidateSize(file)){

            }else{
                return;
            }

            let formData:FormData = new FormData();
            formData.append('file', file, file.name);
            console.log(this.fileList);

            //Upload files to server
            observablesList.push(this.http.post('https://scribar.herokuapp.com/api/file/upload/video', formData, {
                            headers: new HttpHeaders({'Authorization': this.authService.idToken})
                        }));

        }

        //Observable for upload complete event
        Observable.forkJoin(observablesList).subscribe(results => {

            console.log(results);

            this.steps[this.currentStep].step.videoUrl = "https://scribar.herokuapp.com/api/file/download/video?file_name=" + results[0].file_name;
            console.log(this.steps[this.currentStep].step.videoUrl);



        });

}


ValidateSize(file) {
        var FileSize = file.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 10) {
            alert('File size exceeds 10 MB');
        } else {

        }

        return FileSize < 10;
}


    saveAll(){



       for(var i = 0; i < this.steps.length; i++){

            this.steps[i]['step']['step_index'] = i;

       }

       console.log(this.steps);


        this.http.post('https://scribar.herokuapp.com/api/dashboard/update/steps?task_id=' + this.route.snapshot.params.task_id,
        {task_id: this.route.snapshot.params.task_id, steps: this.steps}, this.authService.httpOptions).subscribe(data => {


                console.log(data);


          this.http.get('https://scribar.herokuapp.com/api/dashboard/get/steps?task_id=' + this.route.snapshot.params.task_id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting steps from task by id " + this.route.snapshot.params.task_id + "...");

                                        this.steps = <any[]>data;

                                        this.http.post('https://scribar.herokuapp.com/api/flowchart/generate/markup',{task_id: this.route.snapshot.params.task_id, steps: this.steps}, this.authService.httpOptions).subscribe(data => {

                                                        var markup = data['markup'];

                                                        mermaid.mermaidAPI.render('id1', markup, (svg) => {
                                                                                      this.ngZone.run(() => {

                                                                                   this.diagram = this.domSanitizer.bypassSecurityTrustHtml(svg);

                                                                                     });
                                                                                });
                                                    });



                                        console.log(this.steps);

                                          for(var i = 0; i < this.steps.length; i++){

                                                console.log(this.steps[i]['icon']['file_path']);
                                                this.steps[i]['step']['img_src'] = this.steps[i]['icon']['file_path'];

                                           }


                                    },
                                    (error) => {

                                        console.log(error);


                                        });





            });
    }

    generateMarkup(){

        this.http.post('https://scribar.herokuapp.com/api/flowchart/generate/markup',
        {task_id: this.route.snapshot.params.task_id, steps: this.steps}, this.authService.httpOptions).subscribe(data => {

                //console.log(data['markup']);
                var markup = data['markup'];


                                                            let that = this

                                                                setTimeout(() => {

                                                                                    var nodes =  this.elRef.nativeElement.querySelectorAll('g.node');
                                                                                    console.log(nodes);

                                                                                  for (var i = 0; i < nodes.length; i++) {
                                                                                        var node = nodes[i];
                                                                                        console.log(node);
                                                                                        node['angular'] = that;
                                                                                        node.removeEventListener('click', this.openConditionDialogFromDiagram.bind(node));
                                                                                        node.addEventListener('click', this.openConditionDialogFromDiagram.bind(node));
                                                                                        node.addEventListener('mouseover', this.onHover.bind(node));
                                                                                        node.addEventListener('mouseout', this.onExitHover.bind(node));

                                                                                    }
                                                                                     }, 4000);



                mermaid.mermaidAPI.render('id1' + Math.floor(Math.random() * (500 - 1 + 1)) + 1, markup, (svg) => {

                                                 //console.log(svg);
                                                 var template = this.domSanitizer.bypassSecurityTrustHtml(svg);
                                                 //console.log(template);

                                              this.ngZone.run(() => {

                                                    this.diagram = template;
                                                    //console.log(this.diagram);
                                                    this.ref.detectChanges();
                                             });


                                        });
            });

    }



    addNewStep(){

        this.steps.push({step:{step_title: this.newStepText, expected_time: this.newStepTime, step_index:this.steps.length, task_id:this.route.snapshot.params.task_id}, aids:[], routes:[], conditions:[]});
        console.log(this.steps);
        this.newStepText = "";


        this.saveAll();


    }

    removeStep(index){


        console.log(index);

        this.steps.splice(index, 1);

        console.log(this.steps);

       //Also done server side
    }




    addNewCondition(list){

        list.push({condition_index:list.length, condition_query:this.newConditionText, step_id:this.shownStepInModal['step']['step_id']});
        console.log(list);


    }


    addNewRoute(list){

        var result_string = "";

        console.log(this.newRoute.results);

        for(var i = 0; i < this.newRoute.results.length;i++){
            if(this.newRoute.results[i] === "1"){
                result_string += "T";
            }
            if(this.newRoute.results[i] === "2"){
                result_string += "F";
            }

        }

        console.log(result_string);

        list.push({condition_result:result_string, result_step_index:this.newRoute.step_index, step_id:this.shownStepInModal['step']['step_id']});
        console.log(list);

        this.showAddRouteModal = false;

        this.generateMarkup();


    }


    setDiagram(d){
        this.diagram = d;
    }


  ngOnInit() {

    //window.my = window.my || {};
    //window.my.namespace = window.my.namespace || {};
    //window.my.namespace.onNodeClicked = this.onNodeClicked.bind(this);


  }


   onNodeClicked() {
    //this.ngZone.run(() => this.privateFunc());
  }


    openConditionDialogFromDiagram() {

    if(this['id'] === "start" || this['id'] === "end"){
            return;
        }

    var index = parseInt(this['id'].replace('s', ''));


        let dialogRef =   this['angular'].dialog.open(ConditionsDialog, {
      width: '500px',
      data: {step: this['angular'].steps[index], length: this['angular'].steps.length}
    });

       dialogRef.afterClosed().subscribe(confirm => {

            this['angular'].generateMarkup();

            });


    return;

/*
        console.log(index);

           this['angular'].ngZone.run(() => {
                   this['angular'].toggleConditionsModal(this['angular'].steps[index]);
            });

        console.log(this['id']);

*/

    }

  onTabChanged(){
    console.log("tag changed!!!");


let that = this

                                                                setTimeout(() => {

                                                                                    var nodes =  this.elRef.nativeElement.querySelectorAll('g.node');
                                                                                    console.log(nodes);

                                                                                  for (var i = 0; i < nodes.length; i++) {
                                                                                        var node = nodes[i];
                                                                                        console.log(node);
                                                                                        node['angular'] = that;
                                                                                        node.removeEventListener('click', this.openConditionDialogFromDiagram.bind(node));
                                                                                        node.addEventListener('click', this.openConditionDialogFromDiagram.bind(node));
                                                                                        node.addEventListener('mouseover', this.onHover.bind(node));
                                                                                        node.addEventListener('mouseout', this.onExitHover.bind(node));

                                                                                    }
                                                                                     }, 1000);

    }

    onHover(){
        this['angular'].elRef.nativeElement.querySelector("#" + this['id']).classList.add('left-leg');
    }

     onExitHover(){
        this['angular'].elRef.nativeElement.querySelector("#" + this['id']).classList.remove('left-leg');
    }


    nextPM(){

        if(this.currentPMStep < this.steps.length - 1){

            this.currentPMStep++;

            var msg = new SpeechSynthesisUtterance(this.steps[this.currentPMStep]['step']['step_title']);
            (<any>window).speechSynthesis.speak(msg)

        }

    }

    backPM(){

        if(this.currentPMStep > 0){

                this.currentPMStep--;

        }

    }

}




//Ability to delete conditions
//Change how t and f are defined
//Save indexs so that order persists in



//Steps should be redownloaded after every save!!!!!!
