import { Component, OnInit, NgZone } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {MatSnackBar} from '@angular/material';
import { AuthService } from '../../../services/auth.service';
import {VgAPI} from 'videogular2/core';
import {HelpDialog} from '../../company/help-dialog/help.component';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-instructor-dashboard-live',
  templateUrl: './instructor-dashboard-live.component.html',
  styleUrls: ['./instructor-dashboard-live.component.scss']
})
export class InstructorDashboardLiveComponent implements OnInit {

  private serverUrl = 'https://scribar.herokuapp.com/socket';
  private stompClient;

    public step_text = "Elevate rectus muscle";
    public icon_path = "";
    public index: number = 10;
    public showText: boolean = true;

    public alpha = "abcdefghijklmnopqrstuvwxyz";


    public rubricSent = false;

    public steps: any[];
    public task: any = {};
    public profile:any = {};

    public rubric:any = {rubric:{request_match_id:0, comments:"", was_proficient:false},steps:[]};

   public editor_config = {
    editable: true,
    spellcheck: true,
    height: "500",
    minHeight: "500"
    };

    public start_time = "";

  public isMatchActive = false;

  constructor(private http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar, public authService: AuthService, private ngZone: NgZone) {
        this.connectToServer();
    }

  ngOnInit() {
  }

  public connectToServer(){

        let ws = new SockJS(this.serverUrl);

        this.stompClient = Stomp.over(ws);
        let that = this;

        that.stompClient.connect({}, function(frame) {

            console.log("connected!!");

          that.stompClient.subscribe("/dispatcher/" + that.authService.userProfile['i']['instructor_id'], (message) => {
            console.log(message);

            if(message.body) {

              console.log(message.body);

                message.body = message.body.replace(/\\n/g, "\\n")
                               .replace(/\\'/g, "\\'")
                               .replace(/\\"/g, '\\"')
                               .replace(/\\&/g, "\\&")
                               .replace(/\\r/g, "\\r")
                               .replace(/\\t/g, "\\t")
                               .replace(/\\b/g, "\\b")
                               .replace(/\\f/g, "\\f");
                // remove non-printable and other non-valid JSON chars
                message.body = message.body.replace(/[\u0000-\u0019]+/g,"");


              var obj = JSON.parse(message.body.replace(/\n|\r/g, ""));

              if(obj['type'] === 'MATCH_ACTIVE'){

                 that.isMatchActive = true;

                 var task_id = obj['task_id'];
                 var match_id = obj['request_match_id'];
                 var profile_id = obj['profile_id'];

                  that.snackBar.open('User joined. ', '',{
                                                    duration: 1500
                 });


                        that.rubric.rubric.request_match_id = match_id;


                    that.start_time = new Date() + "";

                               that.http.get('https://scribar.herokuapp.com/api/profile/get/profile?profile_id=' + profile_id, that.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting profile by id " + profile_id + "...");
                                        that.profile = data;
                                        console.log(that.profile);
                                    },
                                    (error) => {


                                        console.log('ERROR GETTING PROFILE!!!');
                                        console.log(error);


                                        });

                  that.http.get('https://scribar.herokuapp.com/api/dashboard/get/task?task_id=' + task_id, that.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting task by id " + task_id + "...");
                                        that.task = data;
                                        console.log(that.task);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

                 that.http.get('https://scribar.herokuapp.com/api/dashboard/get/steps?task_id=' + task_id, that.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting steps from task by id " + task_id + "...");

                                        var list = <any[]>data;

                                        for(var i = 0; i < list.length; i++){
                                              that.rubric.steps.push({step_id:list[i]['step']['step_id'], step_index:list[i]['step']['step_index'], passed:false});
                                        }

                                        that.steps = list;

                                        console.log(that.steps);
                                        console.log(that.rubric);


                                    },
                                    (error) => {

                                        console.log(error);


                                        });






              }

              if(obj['type'] === 'MATCH_INACTIVE'){


                     let dialogRef = that.dialog.open(HelpDialog, {
                        width: '500px',
                        data: {title:"Task Complete", content:"The user has ended the task."}
                      });


              }

               if(obj['type'] === 'MATCH_STEP_UPDATE'){

                that.step_text = obj['step_title'];
                that.index = obj['step_index'];
                that.icon_path = obj['icon_path'];

              }


            }

          });

        });



  }


   public saveRubric(){

        this.http.post('https://scribar.herokuapp.com/api/matching/save/rubric',this.rubric, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);

                                        this.rubricSent = true;

                                            let dialogRef = this.dialog.open(HelpDialog, {
                                                width: '500px',
                                                data: {title:"Rubric submitted", content:"Your rubric has been sent thank you!"}
                                            });
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }

}
