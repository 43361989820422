import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-view-account',
  templateUrl: './company-view-account.component.html',
  styleUrls: ['./company-view-account.component.css']
})
export class CompanyViewAccountComponent implements OnInit {

    product_key = "";

  constructor(public authService: AuthService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {

    if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
       this.router.navigate(['/d/admin']);
    }

    if(this.authService.userProfile['type'] === this.authService.TYPE_GENERAL_USER){
       this.router.navigate(['/login']);
    }

      //Get value of constants
    this.http.get('https://scribar.herokuapp.com/api/dashboard/get/product/key?cid=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all product key...");
                                        console.log(data);
                                        this.product_key = data['product_key'];
                                    },
                                    (error) => {

                                        console.log(error);

                                        });

    console.log(this.authService.userProfile);



    }

  ngOnInit() {
  }

}
