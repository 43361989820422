import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';

/*
*
* Author: Saboor Salaam
*
*/


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})


//Admin dashboard page
export class AdminDashboardComponent implements OnInit {

  search_term = "";
  workflows: any[];
  tasks: any[];
  groups: any[];
  profiles: any[];
  companies: any[];
  filters = {
    company_id:-99
   };


  constructor( public authService: AuthService, private http: HttpClient, private router: Router) {

//Get all companies from server
this.http.get('https://scribar.herokuapp.com/api/admin/get/all/organizations', this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.companies = <any[]> data;
                                        this.companies.unshift({company_name:"All", company_id:-99})
                                        console.log(this.companies);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });
//Get all workflows from server
 this.http.get('https://scribar.herokuapp.com/api/admin/get/all/workflows', this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.workflows = <any[]>data;
                                        console.log(this.workflows);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });
//Get all tasks from server
this.http.get('https://scribar.herokuapp.com/api/admin/get/all/tasks', this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.tasks = <any[]> data;
                                        console.log(this.tasks);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });
//Get all groups from server
this.http.get('https://scribar.herokuapp.com/api/admin/get/all/groups', this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.groups = <any[]> data;
                                        console.log(this.groups);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });
//Get all profiles from server
this.http.get('https://scribar.herokuapp.com/api/admin/get/all/profiles', this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.profiles = <any[]> data;
                                        console.log(this.profiles);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

 }

  ngOnInit() {
  }

    //Open group navigate to page
    openGroup(id){
    this.router.navigate(['/d/organization/view/group', id]);
    }

    //Open workfow navigate to page
    openWorkflow(id){
    this.router.navigate(['/d/organization/view/workflow', id]);
  }

    //Open task navigate to page
    openTask(task_id, workflow_id){
    this.router.navigate(['/d/organization/view/task', workflow_id, task_id]);
  }

    //Open profile navigate to page
    openProfile(id){
       this.router.navigate(['/d/organization/view/profile', id]);
     }

}
