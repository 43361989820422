import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ENTER, COMMA} from '@angular/cdk/keycodes';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm.html',
  styleUrls: ['./confirm.css']
})
export class ConfirmDialog {

    constructor(
   
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
