import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-performance-record',
  templateUrl: './company-dashboard-view-performance-record.component.html',
  styleUrls: ['./company-dashboard-view-performance-record.component.scss']
})

//Page to view performance report
export class CompanyDashboardViewPerformanceRecordComponent implements OnInit {


    public record:any;
    public report:any;
    steps:any[];
    task:any;


   constructor(public authService: AuthService, public domSanitizer: DomSanitizer, private http: HttpClient, private router: Router,  private route: ActivatedRoute) {
        console.log(this.route.snapshot.params.performance_record_id);

     //Check for performance record id in url paramas
    if("performance_record_id" in this.route.snapshot.params){
                //Get generated report from server
  this.http.get('https://scribar.herokuapp.com/api/performance/generate/report?performance_record_id=' + this.route.snapshot.params.performance_record_id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.report = data;
                                        console.log(this.report);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });
    }else{

        this.router.navigate(['/d/organization']);

    }

 }

  openStep(step_id){
    console.log(step_id);
    this.router.navigate(['d/organization/view/performance/record/step', this.record['performanceRecord']['performance_record_id'], step_id]);
  }



  ngOnInit() {
  }


//Dummy data
  public invoice = {
        'from'    : {
            'title'  : 'Fuse Inc.',
            'address': '2810 Country Club Road Cranford, NJ 07016',
            'phone'  : '+66 123 455 87',
            'email'  : 'hello@fuseinc.com',
            'website': 'www.fuseinc.com'
        },
        'client'  : {
            'title'  : 'John Doe',
            'address': '9301 Wood Street Philadelphia, PA 19111',
            'phone'  : '+55 552 455 87',
            'email'  : 'johndoe@mail.com'
        },
        'number'  : 'P9-0004',
        'date'    : 'Jul 19, 2015',
        'dueDate' : 'Aug 24, 2015',
        'services': [
            {
                'title'    : 'Prototype & Design',
                'detail'   : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan, quam sed eleifend imperdiet.',
                'unit'     : 'Hour',
                'unitPrice': '12.00',
                'quantity' : '240',
                'total'    : '2880'
            },
            {
                'title'    : 'Coding',
                'detail'   : 'Vestibulum ligula sem, rutrum et libero id, porta vehicula metus. Cras dapibus neque sit amet laoreet vestibulum.',
                'unit'     : 'Hour',
                'unitPrice': '10.50',
                'quantity' : '350',
                'total'    : '3675'
            },
            {
                'title'    : 'Testing',
                'detail'   : 'Pellentesque luctus efficitur neque in finibus. Integer ut nunc in augue maximus porttitor id id nulla. In vitae erat.',
                'unit'     : 'Hour',
                'unitPrice': '4.00',
                'quantity' : '50',
                'total'    : '200'
            },
            {
                'title'    : 'Documentation & Training',
                'detail'   : 'Pellentesque luctus efficitur neque in finibus. Integer ut nunc in augue maximus porttitor id id nulla. In vitae erat.',
                'unit'     : 'Hour',
                'unitPrice': '6.50',
                'quantity' : '260',
                'total'    : '1690'
            }
        ],
        'subtotal': '8445',
        'tax'     : '675.60',
        'discount': '120.60',
        'total'   : '9000'
    };

}
