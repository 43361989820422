import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-create-workflow',
  templateUrl: './company-dashboard-create-workflow.component.html',
  styleUrls: ['./company-dashboard-create-workflow.component.scss']
})


//page to create a new workflow
export class CompanyDashboardCreateWorkflowComponent implements OnInit {

  workflow:any = {workflow_name: "", workflow_description: "", company_id: "",cover_img:"", hasImage:false};

  base64Cover = "";
  postButtonText = "Save";
  hasError = false;
  errorText = "Error creating workflow";
  public fileList;
  public uploading = false;

  constructor(public location: Location, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {

        //Set company id of new workflow
        this.workflow.company_id = this.authService.userProfile['c']['company_id'];

}

  ngOnInit() {
  }

        //Listener for new file selected for workflow cover
       coverFileChange(event) {

        let observablesList = [];

        //Get list of selected files
        this.fileList = event.target.files;

        this.uploading = true;

        if(this.fileList.length > 0) {

            //Get first file from list
            let file: File = this.fileList[0];
            let formData:FormData = new FormData();
            formData.append('file', file, file.name);
            console.log(this.fileList);

            //Upload file to server
            observablesList.push(this.http.post('https://scribar.herokuapp.com/api/file/upload/image', formData, {
                            headers: new HttpHeaders({'Authorization': this.authService.idToken})
                        }));

        }


        //When upload is complete
        Observable.forkJoin(observablesList).subscribe(results => {
            console.log(results);
            alert("All uploaded!");
            this.uploading = false;

            var path = results[0].file_path;
            this.workflow.cover_img = path;

            //Get newly uploaded image from server
            this.http.get(encodeURI('https://scribar.herokuapp.com/api/profile/get/image?path=' +  path), this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                        this.base64Cover = data['file_path'];
                                        this.workflow.hasImage = true;
                                    },
                                    (error) => {
                                        console.log(error);
                                    });



        });

}

 //Save new workflow
  postWorkflow(){

   this.postButtonText = "Creating...";

    //Send workflow data to server in post request
    this.http.post('https://scribar.herokuapp.com/api/dashboard/create/workflow', this.workflow, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {


                                        //If success navigate to workflows page
                                        console.log(data);
                                        this.postButtonText = "Save";
                                       this.router.navigate(['/d/organization/workflows']);

                                    },
                                    (error) => {

                                        //If error shows prompt to user describing error
                                        console.log(error);
                                        this.postButtonText = "Save";
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "A workflow with this name already exists!";
                                        }else{
                                        this.errorText = "Error creating workflow."
                                        }});

  }

}
