import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import {RoutesDialog} from '../routes-dialog/routes.component';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'conditions-dialog',
  templateUrl: './conditions.html',
  styleUrls: ['./conditions.css']
})
export class ConditionsDialog {

    public shownStepInModal: any;
    public newConditionText = "";
    public step_length = 0;

    


    constructor(
   public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConditionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

        console.log(data);
        
        this.shownStepInModal = data['step'];
        this.step_length = data['length'];

    }

    onNoClick(): void {
      this.dialogRef.close();
    }

     addNewCondition(list){
    
        list.push({condition_index:list.length, condition_query:this.newConditionText, step_id:this.shownStepInModal['step']['step_id']});
        console.log(list);

    }

    addRoute(){


        let dialogRef = this.dialog.open(RoutesDialog, {
            width: '500px',
            data: {step:this.shownStepInModal, length:this.step_length}
          });

          dialogRef.afterClosed().subscribe(confirm => {
      
                this.dialogRef.close();
      
            });


    }

}
