import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageService } from 'angular-2-local-storage';

/*
*
* Author: Saboor Salaam
*
*/


@Component({
  selector: 'app-company-dashboard-create-group',
  templateUrl: './company-dashboard-create-group.component.html',
  styleUrls: ['./company-dashboard-create-group.component.scss']
})


//Page to create a new group
export class CompanyDashboardCreateGroupComponent implements OnInit {

    public group = {
    group_description:"",
    group_name:"",
    workflow_id: 1
    };

    public workflows;

    dumb = "";
    postButtonText = "Save";
    hasError = false;
    errorText = "Error creating group";


  constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService) {

    console.log("Create group");



        //Get workflows from server to populate workflow selection drop down
       this.http.get('https://scribar.herokuapp.com/api/dashboard/get/workflows?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting workflows...");
                                        console.log(data);
                                        this.workflows = data;

                                        if(this.workflows.length > 0){
                                            this.group.workflow_id = this.workflows[0].workflow_id;
                                        }

                                    },
                                    (error) => {

                                        console.log(error);


                                        });

     }

     //Save new group
     createGroup(){
   this.postButtonText = "Loading...";

            console.log("Saving group...");

            //Send group data to server
            this.http.post('https://scribar.herokuapp.com/api/profile/create/group', {company_id:this.authService.userProfile['c']['company_id'] , group_name: this.group.group_name, group_description: this.group.group_description, workflow_id: this.group.workflow_id},this.authService.httpOptions) .subscribe(

                                    (data) => {
                                                    console.log(data);
                                                    this.postButtonText = "Save";

                                                    //Navigate back to groups page
                                                    this.router.navigate(['/d/organization/groups']);
                                    },
                                    (error) => {

                                        console.log(error);

                                        //Show error prompt to user
                                        console.log(error);
                                        this.postButtonText = "Save";
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "A group with this name already exists!";
                                        }else{
                                        this.errorText = "Error creating group."
                                        }


                                        });

     }

  ngOnInit() {
  }

}
