import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {


  positiveCount=0;
  negativeCount=0;

  public doughnutChartLabels: string[] = [ 'Positive', 'Negative',];
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
      { data: [ 0, 0 ], label: 'Series A' },
  ];

  public chartColors: any[] = [
    { 
      backgroundColor:["#008000", "#ff0000"] 
    }];

  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: false
  };

  report_id = '1234';
  report:any = {};
  loadingData = true;

  score = 100;

  constructor(private http: HttpClient, public authService: AuthService) { 

    this.http.get('https://scribar.herokuapp.com/api/profile/get/report?report_id=' + this.report_id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                       this.report = JSON.parse(data['json_data']);

                                       this.report.flagged_words = this.report.flagged_words.split(",");

                                       console.log("Getting report data", this.report);

                                       this.loadingData = false;

                                       this.score -= (this.report.flagged_words.length * 3);
                                       this.score -= (this.report.sentiment.filter(s => s.label=='anger').length * 10);

                                       this.doughnutChartDatasets=
                                       [
                                        { data: [ this.report.sentiment.filter(s => s.label!=='anger').length,
                                        this.report.sentiment.filter(s => s.label==='anger').length], label: 'Series A' },
                                       ]

                                       console.log(this.score);

                                       console.log(this.doughnutChartDatasets);


                                    },
                                    (error) => {
                                        console.log(error);
                                        this.loadingData = false;
                                        });



  }

  ngOnInit() {
  }

}
