import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-performance-records',
  templateUrl: './company-dashboard-view-performance-records.component.html',
  styleUrls: ['./company-dashboard-view-performance-records.component.css']
})


//Page to view performance records NOT USED ANY MORE
export class CompanyDashboardViewPerformanceRecordsComponent implements OnInit {

  records = {};

  constructor(public authService: AuthService, private http: HttpClient, private router: Router,  private route: ActivatedRoute) {
        console.log(this.route.snapshot.params.performance_profile_id);


 if(this.authService.userProfile['type'] === this.authService.TYPE_GENERAL_USER){
       this.router.navigate(['/d/user']);
    }

     if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
       this.router.navigate(['/d/admin']);
    }

    console.log(this.authService.userProfile);


    if("performance_profile_id" in this.route.snapshot.params){

              this.http.get('https://scribar.herokuapp.com/api/performance/get/records/by/profile?performance_profile_id=' + this.route.snapshot.params.performance_profile_id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.records = data;
                                        console.log(this.records);


                                    },
                                    (error) => {

                                        console.log(error);


                                        });
    }else{

        this.router.navigate(['/d/organization']);

    }

 }

  ngOnInit() {
  }

}
