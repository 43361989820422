import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-edit-workflow',
  templateUrl: './company-dashboard-edit-workflow.component.html',
  styleUrls: ['./company-dashboard-edit-workflow.component.scss']
})

//Page to edit a workflow
export class CompanyDashboardEditWorkflowComponent implements OnInit {

    public workflow: any;

  postButtonText = "Save";
  hasError = false;
  errorText = "Error updating workflow";
  public fileList;
  public uploading = false;
  cover = "";
  public hasImage = false;

    constructor(public location: Location, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {

    console.log(this.authService.userProfile);

    //Check for id in params
    if("id" in this.route.snapshot.params){

              //Get workflow from server
              this.http.get('https://scribar.herokuapp.com/api/dashboard/get/workflow?workflow_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.workflow = data;

                                        //If workflow has image
                                        if(this.workflow.cover_img !== "" || this.workflow.cover_img !== null){

                                            this.hasImage = true;

                                        }else{
                                            this.hasImage = false;
                                        }

                                    },
                                    (error) => {

                                        console.log(error);

                                        });


    }else{
        //If no id in params navigate away from page
        this.router.navigate(['/d/organization']);

    }



    }

  ngOnInit() {
  }

    //Listener for file selected event
   coverFileChange(event) {

        let observablesList = [];

        //Get list of uploaded files
        this.fileList = event.target.files;

        this.uploading = true;

        if(this.fileList.length > 0) {

            let file: File = this.fileList[0];
            let formData:FormData = new FormData();
            formData.append('file', file, file.name);
            console.log(this.fileList);

            //Upload files to server
            observablesList.push(this.http.post('https://scribar.herokuapp.com/api/file/upload/image', formData, {
                            headers: new HttpHeaders({'Authorization': this.authService.idToken})
                        }));

        }

        //Observable for upload complete event
        Observable.forkJoin(observablesList).subscribe(results => {
            console.log(results);
            alert("All uploaded!");
            this.uploading = false;

            var path = results[0]['file_path'];
            this.workflow.cover_img = path;

            this.hasImage = true;



        });

}


  //Save workflow
  postWorkflow(){


   this.postButtonText = "Updating...";

    //Send updated workflow data to server
    this.http.post('https://scribar.herokuapp.com/api/dashboard/update/workflow', this.workflow, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        //If success navigate back to workflows page
                                        this.postButtonText = "Save";
                                       this.router.navigate(['/d/organization/workflows']);

                                    },
                                    (error) => {

                                        //If error show prompts to user
                                        console.log(error);
                                        this.postButtonText = "Save";
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "Another workflow with this name already exists!";
                                        }else{
                                        this.errorText = "Error updating workflow."
                                        }

   });

  }

}
