import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {ConfirmDialog} from '../dashboard/company/confirm-dialog/confirm.component';
import {HelpDialog} from '../dashboard/company/help-dialog/help.component';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Injectable()
export class AuthService {

   public everyThingReady: Observable<string>; //Observable to notify when user authentication state and profile data has been retrieved
   public userProfile = null;
   public pendingUserRequests;
   public idToken: string = "";
   public httpOptions = {};
   public TYPE_GENERAL_USER: number = 1;
   public TYPE_COMPANY: number = 2;
   public TYPE_JUXTOPIA_ADMIN : number = 3;
   public TYPE_INSTRUCTOR : number = 4;

   public ROLE_GENERAL: number = 1;
   public ROLE_ADMIN : number = 2;

   public isDemo = false;

  constructor(public dialog: MatDialog, private router: Router, private http: HttpClient,  private localStorageService: LocalStorageService) {

       this.everyThingReady = new Observable((observer) => {

            if(this.localStorageService.get('current_user')){

                console.log(this.localStorageService.get('current_user')['token']);

                this.idToken = this.localStorageService.get('current_user')['token'];

                this.httpOptions = {
                            headers: new HttpHeaders({
                              'Authorization': this.idToken
                            })
                        };

                 this.http.get('https://scribar.herokuapp.com/api/account/get/data', this.httpOptions)
                            .subscribe(
                            (data) => {

                                this.userProfile = data;
                                console.log("Getting user profile data...");
                                console.log(data);

                       if(this.userProfile['type'] === this.TYPE_COMPANY){

                                this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.userProfile['c']['company_id'], this.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting company requests...");
                                        console.log(data);
                                       this.pendingUserRequests = data;

                                        observer.next("done");
                                        observer.complete();

                                    },
                                    (error) => {

                                        console.log(error);
                                         observer.next("done");
                                        observer.complete();
                                        this.localStorageService.remove("current_user");

                                        });

                            } else if(this.userProfile['type'] === this.TYPE_GENERAL_USER){3


                                    this.http.get('https://scribar.herokuapp.com/api/account/get/company/for/user?user_id=' + this.userProfile['u']['user_id'], this.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting parent company for user...");
                                        console.log(data);
                                        this.userProfile['c'] = data;


                                        //When user first signs up init user with a role!!!

                                        this.http.get('https://scribar.herokuapp.com/api/account/get/role/for/user?user_id=' + this.userProfile['u']['user_id'], this.httpOptions)
                                                    .subscribe(
                                                    (data) => {

                                                    console.log("Getting role for user...");
                                                    console.log(data);
                                                    this.userProfile['u']['role'] = data;

                                                    if(this.userProfile['u']['role'].role_id === this.ROLE_ADMIN){

                                                    this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.userProfile['c']['company_id'], this.httpOptions)
                                                        .subscribe(
                                                        (data) => {

                                                            console.log("Getting company requests...");
                                                            console.log(data);
                                                           this.pendingUserRequests = data;

                                                            observer.next("done");
                                                            observer.complete();

                                                        },
                                                        (error) => {

                                                            console.log(error);
                                                             observer.next("done");
                                                            observer.complete();
                                                            this.localStorageService.remove("current_user");

                                                            });
                                                    }else{

                                                        observer.next("done");
                                                        observer.complete();

                                                    }

                                                    },
                                                    (error) => {

                                                        console.log(error);
                                                         observer.next("done");
                                                        observer.complete();
                                                        this.localStorageService.remove("current_user");

                                                        });


                                    },
                                    (error) => {

                                        console.log(error);
                                         observer.next("done");
                                        observer.complete();
                                        this.localStorageService.remove("current_user");

                                        });

                            }else{

                           observer.next("done");
                           observer.complete();

                            }

                            },
                            (error) => {
                                console.log(error);
                                 observer.next("done");
                                        observer.complete();
                                 this.localStorageService.remove("current_user");
                                });
            }else{

                this.userProfile = null;
                this.localStorageService.remove("current_user");
                 observer.next("done");
                observer.complete();

            }

       });

}

signInWithEmail(email, password) {
    return this.http.post('https://scribar.herokuapp.com/login', {username: email, password: password}, { observe: 'response' });
}

logout() {
    this.userProfile == null;
    this.localStorageService.remove("current_user");
    this.router.navigate(['login']);
  }

isLoggedIn() {

  if (this.userProfile == null ) {
      return false;
    } else {
      return true;
    }
}


   showHelp(title, content){
        let dialogRef = this.dialog.open(HelpDialog, {
          width: '500px',
          data: {title:title, content:content}
        });
    }


}
