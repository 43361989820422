import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import {ConfirmDialog} from '../confirm-dialog/confirm.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-groups',
  templateUrl: './company-dashboard-groups.component.html',
  styleUrls: ['./company-dashboard-groups.component.scss']
})


//Page to view all groups
export class CompanyDashboardGroupsComponent{

  public groups;
  public search_string: string = "";


  constructor(public authService: AuthService, public dialog: MatDialog, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService) {

        //Get all groups for company from server
         this.http.get('https://scribar.herokuapp.com/api/profile/get/groups/by/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting groups...");
                                        console.log(data);
                                        this.groups = data;
                                        console.log(this.groups);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });


}

  //Navigate to add new group page
  addNewGroup(){
    this.router.navigate(['/d/organization/create/group']);
  }

  //Navigate to open group page
  openGroup(id){
    this.router.navigate(['/d/organization/view/group', id]);
  }


 removeGroup(id){

let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(confirm => {

        if(confirm){

            this.http.get('https://scribar.herokuapp.com/api/profile/remove/group?profile_group_id=' + id, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        //Get all groups for company from server
         this.http.get('https://scribar.herokuapp.com/api/profile/get/groups/by/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting groups...");
                                        console.log(data);
                                        this.groups = data;
                                        console.log(this.groups);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

                                    },
                                    (error) => {


                                        });


        }else{

        }

    });


  }


}
