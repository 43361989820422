import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-edit-task',
  templateUrl: './company-dashboard-edit-task.component.html',
  styleUrls: ['./company-dashboard-edit-task.component.scss']
})


//Page to edit a task
export class CompanyDashboardEditTaskComponent implements OnInit {

     task:any;
     postButtonText = "Save";
     hasError = false;
     errorText = "Error creating task";

      constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {


   //Check for id in params
   if("id" in this.route.snapshot.params){

              //Get task from server
              this.http.get('https://scribar.herokuapp.com/api/dashboard/get/task?task_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting task by id " + this.route.snapshot.params.id + "...");
                                        this.task = data;
                                        console.log(data);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });


    }else{

        //if no id in params navigate away from page
        this.router.navigate(['/d/organization']);

    }


        }

  ngOnInit() {
  }


    //Save task
      postTask(){


   this.postButtonText = "Loading...";

    //Send task data to sever
    this.http.post('https://scribar.herokuapp.com/api/dashboard/update/task', this.task, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                       //If success
                                        console.log(data);
                                        this.postButtonText = "Save";
                                    },
                                    (error) => {
                                        //If error show prompts to user
                                        console.log(error);
                                        this.postButtonText = "Save";
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "Another task with this name already exists!";
                                        }else{
                                        this.errorText = "Error creating workflow."
                                        }

   });

  }

}
