import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { NgForm } from '@angular/forms';

/*
*
* Author: Saboor Salaam
*
*/


@Component({
  selector: 'app-company-dashboard-add-payment-info',
  templateUrl: './company-dashboard-add-payment-info.component.html',
  styleUrls: ['./company-dashboard-add-payment-info.component.scss']
})


//Page to add stripe payment info
export class CompanyDashboardAddPaymentInfoComponent implements AfterViewInit, OnDestroy {

 @ViewChild('cardInfo') cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  public sources;


  constructor(private cd: ChangeDetectorRef,  private http: HttpClient, public authService: AuthService) {}

  //Angular lifecycle method called after view is initialized
  ngAfterViewInit() {
    //this.card = elements.create('card');
    //this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);

//Get existing payments methods for user from server
this.http.get('https://scribar.herokuapp.com/api/payment/get/sources?account_id=1', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                        this.sources = JSON.parse(data['text'])['data'];
                                        console.log(this.sources);
                                    },
                                    (error) => {
                                        console.log(error);

                                        });


  }

  //Remove listeners when component is destroyed
  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  //On user submits new payment info
  async onSubmit(form: NgForm) {

/*
    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
    } else {
      console.log('Success!', token);


      //Send new payment info to server
       this.http.post('https://scribar.herokuapp.com/api/payment/add/source?account_id=1&token=' + token['id'],{}, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);

  //Refresh list of payments sources by retrieving from server
this.http.get('https://scribar.herokuapp.com/api/payment/get/sources?account_id=1', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                        this.sources = JSON.parse(data['text'])['data'];
                                        console.log(this.sources);
                                    },
                                    (error) => {
                                        console.log(error);

                                        });


                                    },
                                    (error) => {
                                        console.log(error);

                                        });


    }

*/
  }


}
