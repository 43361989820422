import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.css']
})

//Page for confirming a password reset from link sent in email
export class ConfirmResetPasswordComponent implements OnInit {

    //User data
     user = {
        email: '',
        password:''
    };

    code = "";

    //Show error message
    hasError = false;

    //Show success message
    hasSuccess = false;


    submitButtonText = "Reset";

  constructor(public authService: AuthService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {

        //Get code and email from url params
        this.code = this.route.snapshot.params.code;
        this.user.email = this.route.snapshot.params.email;

 }

  ngOnInit() {
  }

    //Start password reset
    resetPassword(){

    //Show loading text for submit button
    this.submitButtonText = "Loading...";

          //Send password reset request
           this.http.get('https://scribar.herokuapp.com/account/confirm/reset/password?username=' + this.user.email + "&password=" + this.user.password + "&code=" + this.code, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        //If success show success prompt
                                        console.log(data);
                                        this.hasSuccess = true;
                                        this.hasError = false;

                                        this.submitButtonText = "Reset";


                                    },
                                    (error) => {

                                        //If returns error show error prompt
                                        console.log(error);
                                        this.hasError = true;
                                        this.hasSuccess = false;
                                        this.submitButtonText = "Reset";

                                        });

    }
}
