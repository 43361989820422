import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-company-dashboard-add-overlay',
  templateUrl: './company-dashboard-add-overlay.component.html',
  styleUrls: ['./company-dashboard-add-overlay.component.scss']
})
export class CompanyDashboardAddOverlayComponent implements OnInit {

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  public industries:any[];
  public specialties:any[];
  public objects:any[];
  public animations:any[];
  public model_types:any[];
  public models:any[];


  public selected_industry = -9999;
  public selected_specialty = -99999;
  public selected_object = -999999
  public selected_model:any;
  public selected_model_type = -999999;



  constructor(private _formBuilder: FormBuilder, public location: Location, public authService: AuthService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {

                this.http.get('https://scribar.herokuapp.com/api/dashboard/get/training/industries', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.industries = <any[]>data;
                                        console.log(this.industries);

                                    },
                                    (error) => {

                                        console.log(error);

                                        });


   }


    OnSelectIndustry(event){

        console.log(event);

         this.http.get('https://scribar.herokuapp.com/api/dashboard/get/training/model/specialties?training_industry_id=' + this.selected_industry, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.specialties = <any[]>data;
                                        console.log(this.specialties);

                                    },
                                    (error) => {

                                        console.log(error);

                                        });

        this.http.get('https://scribar.herokuapp.com/api/dashboard/get/training/model/types?training_industry_id=' + this.selected_industry, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.model_types = <any[]>data;
                                        console.log(this.model_types);

                                    },
                                    (error) => {

                                        console.log(error);

                                        });


    }

     OnSelectModelType(event){

        console.log(event);

         this.http.get('https://scribar.herokuapp.com/api/dashboard/get/training/models?training_model_type_id=' + this.selected_model_type, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.models = <any[]>data;
                                        console.log(this.models);

                                    },
                                    (error) => {

                                        console.log(error);

                                        });

    }

    OnSelectSpecialty(event){

        console.log(event);

         this.http.get('https://scribar.herokuapp.com/api/dashboard/get/training/model/objects?training_industry_specialty_id=' + this.selected_specialty, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.objects = <any[]>data;
                                        console.log(this.objects);

                                    },
                                    (error) => {

                                        console.log(error);

                                        });


    }

    OnSelectObject(id){

        this.selected_object = id;

         this.http.get('https://scribar.herokuapp.com/api/dashboard/get/training/model/object/animations?training_model_object_id=' + this.selected_object, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.animations = <any[]>data;
                                        console.log(this.animations);

                                    },
                                    (error) => {

                                        console.log(error);

                                        });

    }


  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

}
