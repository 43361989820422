import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-profile',
  templateUrl: './company-dashboard-view-profile.component.html',
  styleUrls: ['./company-dashboard-view-profile.component.scss']
})

//Page to view performance profile
export class CompanyDashboardViewProfileComponent{

   public profile:any;
   records: any[];
   public constants:any;
   public tasks: any [] = [];


  constructor(public location: Location, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {
    console.log(this.route.snapshot.params.id);

     //Check for profile id in params
    if("id" in this.route.snapshot.params){

//Get all constants from server for dropdowns i.e. certification_level
this.http.get('https://scribar.herokuapp.com/api/profile/get/all/constants', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all constants...");
                                        this.constants = data;
                                        console.log(this.constants);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });

//Get performance profile data from server
              this.http.get('https://scribar.herokuapp.com/api/profile/get/profile?profile_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting profile by id " + this.route.snapshot.params.id + "...");
                                        this.profile = data;
                                        console.log(this.profile);
                                    },
                                    (error) => {


                                        console.log('ERROR GETTING PROFILE!!!');
                                        console.log(error);


                                        });

//Get all performance records for profile from server
       this.http.get('https://scribar.herokuapp.com/api/performance/get/records/by/profile/snapshot?performance_profile_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        this.records = <any[]>data;
                                        console.log("Getting all records...");
                                        console.log(this.records);

                                        for(var i = 0; i < this.records.length; i++){

                                              var task_found = false;

                                              for(var x = 0; x < this.tasks.length; x++){
                                                    if(this.tasks[x]['task_id'] === this.records[i]['task_id']){
                                                        task_found = true;
                                                    }
                                                }

                                                if(!task_found){
                                                    this.tasks.push({task_name:this.records[i]['task_name'], task_id:this.records[i]['task_id']});
                                                }

                                        }

                                        console.log('Tasks found...');
                                        console.log(this.tasks);

                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }else{

        this.router.navigate(['/d/organization']);

    }



    }

//Navigate to edit profile page
    editProfile(){
        this.router.navigate(['/d/organization/edit/profile', this.profile['performance_profile_id']]);
    }

//Navigate to performance report page
  openRecord(task_id){
    this.router.navigate(['d/organization/view/task/performance/record', this.profile['performance_profile_id'], task_id]);
  }

}
