import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class WebsocketserviceService {

 

 public onMessage: Observable<any>;

  constructor(public authService: AuthService) { 

            

  }

  

    public sendMessage(message){

    
    }

  

  

}
