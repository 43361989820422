import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import {MatTableDataSource} from '@angular/material';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-manage-employees',
  templateUrl: './company-dashboard-manage-employees.component.html',
  styleUrls: ['./company-dashboard-manage-employees.component.scss']
})


//Page to manage employee roles
export class CompanyDashboardManageEmployeesComponent implements OnInit {

    employees:any[];
    roles:any[];

    displayedColumns = ['name', 'occupation', 'role'];
    dataSource = new MatTableDataSource([]);

  constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService) {

        //Get all employees for a company
     this.http.get('https://scribar.herokuapp.com/api/dashboard/get/users/company?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting workflows...");
                                        console.log(data);
                                        this.employees = <any[]> data;
                                        this.dataSource = new MatTableDataSource(this.employees);
                                        console.log(this.employees);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });
      //Get all possible roles from server
      this.http.get('https://scribar.herokuapp.com/api/dashboard/get/all/roles', this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting roles...");
                                        console.log(data);
                                        this.roles = <any[]>data;
                                        console.log(this.roles);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });


    }


  ngOnInit() {
  }

    //Function to update an employee role
   updateUserRole (uid, rid){ // Update employee role

        console.log(this.employees);

//Send update role to server for a particular employee
  this.http.get('https://scribar.herokuapp.com/api/dashboard/update/user/role?uid=' + uid + '&rid=' + rid, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {
                                        console.log(data);

                                    },
                                    (error) => {

                                        console.log(error);


                                        });


    }

}
