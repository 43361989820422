import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { WebsocketserviceService } from '../../services/websocketservice.service';

import { LocalStorageService } from 'angular-2-local-storage';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations : fuseAnimations
})

//Page to authenticate a user
export class LoginComponent implements OnInit {

    //Model for user input
    user = {
        email: '',
        password: ''
    };

    //Save credentials
    public rememberMe: boolean = false;

    //show error prompt to use true/false
    public hasError: boolean = false;

    //show progress indicator
    public loginButtonText = "Log In";


    public isRedirect = false;
    public redirectMessage = "";

   constructor(public websocketserviceService: WebsocketserviceService, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService,  private route: ActivatedRoute) {

        //If user is already logged in reroute to dashboard
        if(this.authService.isLoggedIn()){

                console.log("Was logged in");

                    //If user type is company reroute to workflows page
                   if(this.authService.userProfile['type'] === this.authService.TYPE_COMPANY){
                      this.router.navigate(['d/organization/workflows']);
                    }

                    //If user type is juxtopia admin reroute to admin page
                    if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
                      this.router.navigate(['d/admin']);
                    }

                    //If user is a general employee reroute to workflows
                        if(this.authService.userProfile['type'] === this.authService.TYPE_GENERAL_USER){
                      this.router.navigate(['d/organization/workflows']);
                    }

                   if(this.authService.userProfile['type'] === this.authService.TYPE_INSTRUCTOR){
                      this.router.navigate(['d/i/home']);
                    }



        }

        //If user was redirected to login page
        if("isRedirect" in this.route.snapshot.params && "type" in this.route.snapshot.params){

                switch(this.route.snapshot.params.type){

                    //If user has just signed up as an employee or company show prompt
                    case "SIGN_UP_EMPLOYEE":
                        this.isRedirect = true;
                        this.redirectMessage = "Your account has been successfully created and is pending approval. You will receive an email once your account has been approved.";
                    break;
                    case "SIGN_UP_COMPANY":
                        this.isRedirect = true;
                        this.redirectMessage = "Your account has been successfully created. Login to get started.";

                    break;

                }

        }

        //Observable for when authentication status has been retrieved
        this.authService.everyThingReady.subscribe(
        (data) => {

                //If user is already logged in reroute to dashboard
                if(this.authService.isLoggedIn()){
                console.log("Was logged in");

                   if(this.authService.userProfile['type'] === this.authService.TYPE_COMPANY){
                      this.router.navigate(['d/organization/workflows']);
                    }

                      if(this.authService.userProfile['type'] === this.authService.TYPE_GENERAL_USER){
                      this.router.navigate(['d/organization/workflows']);
                    }

                    if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
                      this.router.navigate(['d/admin']);
                    }

                    if(this.authService.userProfile['type'] === this.authService.TYPE_INSTRUCTOR){
                      this.router.navigate(['d/i/home']);
                    }

                }
        }
      );


    }

  ngOnInit() {

  }

 //Perform authentication
signInWithEmail() {


var that = this;

this.loginButtonText = "Signing in...";

//Use auth service to authenticate user
this.authService.signInWithEmail(this.user.email, this.user.password).subscribe(
        (data) => {

                this.loginButtonText = "Log in";


                console.log(data.headers);

                for (let key of data.headers.keys()) {
                    console.log(key);
                }

                //If server responds with authorization header containing token login is successfull
                if(data.headers.has("Authorization")){

                //Get token from header
                var token = data.headers.get("Authorization");


                  //if user has selected 'rememberMe' save token to local storage this.rememberMe

                  if(true){
                  this.localStorageService.set('current_user', {token:token});
                  }

                  //set HTTP header with token to based with ever subsequent request to server
                  this.authService.httpOptions = {
                            headers: new HttpHeaders({
                              'Authorization': token
                            })
                        };

                 //retrieve account data from server once login is successfull
                 this.http.get('https://scribar.herokuapp.com/api/account/get/data', this.authService.httpOptions)
                            .subscribe(
                            (data) => {

                                this.authService.userProfile = data;
                                console.log("Getting user profile data...");
                                console.log(data);

                       //If user is a company
                       if(this.authService.userProfile['type'] === this.authService.TYPE_COMPANY){

                                //Get user registration requests from server
                                this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting company requests...");

                                        //Store requests
                                         this.authService.pendingUserRequests = data;

                                        console.log(this.authService.pendingUserRequests);

                                        //Navigate to dashboard
                                        this.router.navigate(['d/organization/workflows']);


                                    },
                                    (error) => {

                                        //Log user out
                                        console.log(error);
                                        this.localStorageService.remove("current_user");
                                        this.hasError = true;


                                        });
                    }

                   //If user is a general employee
                   if(this.authService.userProfile['type'] === this.authService.TYPE_GENERAL_USER){


                                    //Get parent company data for employee
                                    this.http.get('https://scribar.herokuapp.com/api/account/get/company/for/user?user_id=' + this.authService.userProfile['u']['user_id'], this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting parent company for user...");
                                        console.log(data);
                                        this.authService.userProfile['c'] = data;


                                        //Get users role at company
                                        this.http.get('https://scribar.herokuapp.com/api/account/get/role/for/user?user_id=' + this.authService.userProfile['u']['user_id'], this.authService.httpOptions)
                                                    .subscribe(
                                                    (data) => {

                                                    console.log("Getting role for user...");
                                                    console.log(data);
                                                    this.authService.userProfile['u']['role'] = data;

                                                    if(this.authService.userProfile['u']['role'].role_id === this.authService.ROLE_ADMIN){

                                                    //Get registration requests if user is an admin
                                                    this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                                        .subscribe(
                                                        (data) => {

                                                            console.log("Getting company requests...");
                                                            console.log(data);
                                                           this.authService.pendingUserRequests = data;

                                                           this.router.navigate(['d/organization/workflows']);


                                                        },
                                                        (error) => {

                                                                                                                                                        this.router.navigate(['d/organization/workflows']);

                                                            });
                                                    }else{
                                                                //Navigate to dashboard
                                                                                            this.router.navigate(['d/organization/workflows']);

                                                    }

                                                    },
                                                    (error) => {
                                                            //Log user out if data cannot be retrieved
                                                            console.log(error);
                                                            this.localStorageService.remove("current_user");
                                                            this.hasError = true;


                                                        });


                                    },
                                    (error) => {
                                                            //Log user out
                                                            console.log(error);
                                                            this.localStorageService.remove("current_user");
                                                            this.hasError = true;

                                        });

                    }

                    //If user is juxtopia admin reroute to juxtopia admin page
                   if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
                      this.router.navigate(['d/admin']);
                    }

                    if(this.authService.userProfile['type'] === this.authService.TYPE_INSTRUCTOR){
                      this.router.navigate(['d/i/home']);
                    }

                        },
                            (error) => {
                                console.log(error);
                                 this.localStorageService.remove("current_user");
                                });


                }else{
                 this.hasError = true;

                }



        },(error) => {
            this.loginButtonText = "Log in";
            console.log('error: ' + error);
            this.hasError = true;
        }
      );

    }

}
