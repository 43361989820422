import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {MatTableDataSource} from '@angular/material';
import {ConfirmDialog} from '../confirm-dialog/confirm.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Location} from '@angular/common';


/*
*
* Author: Saboor Salaam
*
*/


@Component({
  selector: 'app-company-dashboard-view-workflow',
  templateUrl: './company-dashboard-view-workflow.component.html',
  styleUrls: ['./company-dashboard-view-workflow.component.scss']
})
export class CompanyDashboardViewWorkflowComponent implements OnInit {

     displayedColumns = ['task_name', 'createdAt', 'actions'];
    dataSource = new MatTableDataSource([]);

    public workflow: any;
    public tasks: any[];

    public loadingData = true;

  constructor(public location: Location, public dialog: MatDialog, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {
    console.log(this.route.snapshot.params.id);


     if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
       this.router.navigate(['/d/admin']);
    }

    console.log(this.authService.userProfile);


    if("id" in this.route.snapshot.params){

              this.http.get('https://scribar.herokuapp.com/api/dashboard/get/workflow?workflow_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting workflow by id " + this.route.snapshot.params.id + "...");
                                        this.workflow = data;
                                        console.log(this.workflow);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });
          this.http.get('https://scribar.herokuapp.com/api/dashboard/get/tasks?workflow_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting tasks from workflow by id " + this.route.snapshot.params.id + "...");
                                        this.tasks = <any[]> data;
                                        console.log(this.tasks);
                                       this.dataSource = new MatTableDataSource(this.tasks);

                                       this.loadingData = false;
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }else{

        this.router.navigate(['/d/organization']);

    }



    }



deleteThisWorkflow(id){


let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(confirm => {

        if(confirm){

            this.http.get('https://scribar.herokuapp.com/api/dashboard/remove/workflow?workflow_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.router.navigate(['/d/organization/workflows']);
                                    },
                                    (error) => {


                                        });


        }else{

        }

    });

  }

  removeTask(id){

let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(confirm => {

        if(confirm){

            this.http.get('https://scribar.herokuapp.com/api/dashboard/remove/task?task_id=' + id, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        this.http.get('https://scribar.herokuapp.com/api/dashboard/get/tasks?workflow_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting tasks from workflow by id " + this.route.snapshot.params.id + "...");
                                        this.tasks = <any[]>data;
                                        console.log(this.tasks);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

                                    },
                                    (error) => {


                                        });


        }else{

        }

    });


  }

  editThisWorkflow(){
    this.router.navigate(['/d/organization/edit/workflow/', this.workflow['workflow_id']]);
  }

 openTask(task_id){
    this.router.navigate(['/d/organization/view/task', this.workflow['workflow_id'], task_id]);
  }

createTask(){
    this.router.navigate(['/d/organization/create/task', this.workflow['workflow_id']]);
  }



  ngOnInit() {
  }

}
