import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

    public showProgress: boolean = false;
    public path: string = "";
    public search_input;

    requests: any [];
    userStatusOptions: any[];
    languages: any;
    selectedLanguage: any;
    showLoadingBar: boolean;
    horizontalNav: boolean;
    noNav: boolean;

    collapsed: boolean;


  constructor(public authService: AuthService,
 private router: Router,
 private fuseConfig: FuseConfigService,
private sidebarService: FuseSidebarService,
private translate: TranslateService,
private http: HttpClient) {


this.collapsed = true;

     router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
                this.showProgress = true;
               this.showLoadingBar = true;

            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                console.log(router.url);
                this.path = router.url;
              this.showProgress = false;
            this.showLoadingBar = false;


            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // Present error to user
                console.log(event.error);
                this.showProgress = true;

            }
        });




this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                'id'   : 'en',
                'title': 'English',
                'flag' : 'us'
            },
            {
                'id'   : 'tr',
                'title': 'Turkish',
                'flag' : 'tr'
            }
        ];

        this.selectedLanguage = this.languages[0];
                this.collapsed = true;

}



  ngOnInit() {
  }

    toggleSidebarOpened(key)
    {
    }

    search(value)
    {
        // Do your search here...
        console.log(value);
    }

    setLanguage(lang)
    {

    }

  collapse()
    {
        this.collapsed = true;
    }

    expand()
    {
        this.collapsed = false;
    }


  respondToRequest(user_id, response){

     this.http.get('https://scribar.herokuapp.com/api/dashboard/respond/request?uid=' + user_id + '&response=' + response, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {
                                        console.log(data);

  this.http.get('https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {
                                        console.log("Getting requests...  " + 'https://scribar.herokuapp.com/api/dashboard/get/requests?cid=' + this.authService.userProfile['c']['company_id']);
                                        console.log(data);
                                        this.requests = <any[]> data;
                                    },
                                    (error) => {

                                        console.log(error);


                                        });


                                    },
                                    (error) => {

                                        console.log(error);

                                        });


    }



}
