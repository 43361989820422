import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';


/*
*
* Author: Saboor Salaam
*
*/


@Component({
  selector: 'app-company-dashboard-create-profile',
  templateUrl: './company-dashboard-create-profile.component.html',
  styleUrls: ['./company-dashboard-create-profile.component.scss']
})

//Page to create new performance profile
export class CompanyDashboardCreateProfileComponent implements OnInit {

   public constants;

   postButtonText = "Save";
   hasError = false;
   errorText = "Error creating profile";

   public profile = {first_name:"", last_name:"",
   profile_group_id: 1, learning_style_id: 1, skill_level_id: 1, ethnicity_id: 1, education_level_id: 1, certification_type_id: 1, certification_level_id: 1, gender_id: 1}



  constructor(public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {

//Get parent group id from url params
this.profile.profile_group_id = this.route.snapshot.params.id;

//Get all constants for drop downs from server
this.http.get('https://scribar.herokuapp.com/api/profile/get/all/constants', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all constants...");
                                        this.constants = data;
                                        console.log(this.constants);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });


  }

  ngOnInit() {
  }

  //Save performance profile
   saveProfile(){
    console.log(this.profile);
   this.postButtonText = "Loading...";

//Send profile data to server in post request
    this.http.post('https://scribar.herokuapp.com/api/profile/create/profile', this.profile, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                        this.postButtonText = "Save";

                                        //Navigate to groups page if success
                                        this.router.navigate(['/d/organization/groups']);

                                    },
                                    (error) => {

                                        //Show appropriate prompts if error is returned

                                        this.postButtonText = "Save";

                                        this.hasError = true;

                                        if(error.status === 409){
                                        this.errorText = "Error creating profile";
                                        }else{
                                        this.errorText = "Error creating profile."
                                        }


                                        });



}

}
