import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-company-dashboard-edit-photo',
  templateUrl: './company-dashboard-edit-photo.component.html',
  styleUrls: ['./company-dashboard-edit-photo.component.scss']
})
export class CompanyDashboardEditPhotoComponent implements OnInit, AfterViewInit {

  public canvas_data = {};

  @ViewChild('canvas') public _canvas: ElementRef;
  @ViewChild('canvas2') public _canvasSmall: ElementRef;

  @Input() public width = 700;
  @Input() public height = 500;

  public canvasHistory: any[] = [];

  public canvasSettings = {color:"#008000",lineWidth:4, lineType:"round", currentTool:"pen"};

  private cx: CanvasRenderingContext2D;
  private cxSmall: CanvasRenderingContext2D;

  private canvas;
  private canvasSmall;
  public origin: any ;

  isDownloadReady = false;
  downloadLink='';

  downloadName='';

  constructor(authService:AuthService,  private route: ActivatedRoute, public sanitizer: DomSanitizer) {
    console.log(this.route.snapshot.params.url);
  }

  ngOnInit() {
  }


  @HostListener('window:resize', ['$event']) onResize(event) {

    console.log("On resize!!");
    console.log("Window width is: " + window.innerWidth);

    this.clearCanvas();


    const canvasEl: HTMLCanvasElement = this._canvas.nativeElement;

    canvasEl['width'] = window.innerWidth;
    canvasEl['height'] = window.innerHeight;

    this.redrawCanvas();

  }

  public ngAfterViewInit() {

    const canvasEl: HTMLCanvasElement = this._canvas.nativeElement;
    this.canvas = canvasEl;
    this.cx = canvasEl.getContext('2d');

    canvasEl['width'] = window.innerWidth;
    canvasEl['height'] = window.innerHeight;


    const canvasEl2: HTMLCanvasElement = this._canvasSmall.nativeElement;
    this.canvasSmall = canvasEl2;
    this.cxSmall = canvasEl2.getContext('2d');

    canvasEl2['width'] = 100;
    canvasEl2['height'] = 100;

    this.cx.lineWidth = this.canvasSettings.lineWidth;
    this.cx.lineCap = this.canvasSettings.lineType;
    this.cx.strokeStyle = this.canvasSettings.color;

    this.captureEvents(canvasEl);

    let background = new Image();
    background.src =  decodeURI(this.route.snapshot.params.url);

    background.onload = ()=> {
      this.cx.drawImage(background,0,0, window.innerWidth, window.innerHeight);

    }


    //this.cx.fillStyle = "white";
    //this.cx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    //this.cxSmall.fillStyle = "white";
    //this.cxSmall.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }



  private captureEvents(canvasEl: HTMLCanvasElement) {
    Observable
      .fromEvent(canvasEl, 'mousedown')
      .subscribe((res: MouseEvent) => {
        const rect = canvasEl.getBoundingClientRect();

        this.origin = {
          x: res.clientX - rect.left,
          y: res.clientY - rect.top
        };

      });

    Observable
      .fromEvent(canvasEl, 'mouseup')
      .subscribe((res: MouseEvent) => {
        const rect = canvasEl.getBoundingClientRect();

        const final = {
          x: res.clientX - rect.left,
          y: res.clientY - rect.top
        };

        this.drawOnCanvas(this.origin, final);

        this.canvasHistory.push({lineWidth:this.cx.lineWidth, lineCap:this.cx.lineCap, strokeStyle:this.cx.strokeStyle, origin:this.origin,final:final});

        console.log(this.canvasHistory);

        this.isDownloadReady = false;

      });



  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {

    if (!this.cx) { return; }


    this.cx.strokeRect(prevPos.x, prevPos.y, (prevPos.x - currentPos.x) * -1, (prevPos.y - currentPos.y) * -1);

    /*
    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }

    */
  }


  setColor(event){

    console.log(event.srcElement.value);

    this.canvasSettings.color = event.srcElement.value;
    this.cx.strokeStyle = this.canvasSettings.color;

  }

  setLineCap(c){

    this.canvasSettings.lineType = c;
    this.cx.lineCap = this.canvasSettings.lineType;

  }

  setLineWidth(w){

    this.canvasSettings.lineWidth = w;
    this.cx.lineWidth = this.canvasSettings.lineWidth;

  }

  setCurrentTool(tool){

    this.canvasSettings.currentTool = tool;

    switch(tool){
      case "marker":
        this.canvasSettings.lineType = "sqaure";

        this.cx.lineWidth = this.canvasSettings.lineWidth;
        this.cx.lineCap = this.canvasSettings.lineType;
        this.cx.strokeStyle = this.canvasSettings.color;
        break;
      case "pen":

        this.canvasSettings.lineType = "round";

        this.cx.lineWidth = this.canvasSettings.lineWidth;
        this.cx.lineCap = this.canvasSettings.lineType;
        this.cx.strokeStyle = this.canvasSettings.color;
        break;
      case "eraser":

        this.canvasSettings.lineType = "sqaure";
        this.cx.strokeStyle = "#fff";
        this.cx.lineWidth = 25;
        break;
    }

  }

  clearCanvasHistory(){

    this.canvasHistory = [];

  }

  clearCanvas(){

    this.cx.clearRect(0,0,this.canvas.width, this.canvas.height);
    this.cx.fillStyle = "white";
    this.cx.fillRect(0, 0, this.canvas.width, this.canvas.height);

  }

  redrawCanvas(){

    this.clearCanvas();


    let background = new Image();
    background.src = decodeURI(this.route.snapshot.params.url);

    background.onload = ()=> {
      this.cx.drawImage(background,0,0, window.innerWidth, window.innerHeight);
      for(let i = 0; i < this.canvasHistory.length;i++){

        this.cx.lineWidth = this.canvasHistory[i]['lineWidth'];
        this.cx.lineCap = this.canvasHistory[i]['lineCap'];
        this.cx.strokeStyle = this.canvasHistory[i]['strokeStyle'];
        this.drawOnCanvas(this.canvasHistory[i]['origin'], this.canvasHistory[i]['final']);

      }

    }



  }

  undo(){
    this.canvasHistory.pop();
    this.redrawCanvas();
  }

  save(){

    this.isDownloadReady = false;
    const file = new Blob([JSON.stringify({image: decodeURI(this.route.snapshot.params.url), canvas: this.canvasHistory})], {type: 'text/plain'});
    console.log(file);

    this.downloadLink  = URL.createObjectURL(file);
    this.isDownloadReady = true;


  }

  onFileChange(event) {

    if (event.target.files.length <= 0){return};

    const file = event.target.files[0];

    const textType = /text.*/;

    console.log(file.type);

    if (file.name.split('.').pop() == 'scribar') {
      const reader = new FileReader();
      reader.onload = (e)=> {

        console.log(reader.result);

        let file_data = JSON.parse(reader.result);

        this.clearCanvas();

        let background = new Image();

        background.src = decodeURI(file_data.image);

        this.canvasHistory = file_data.canvas;

        background.onload = ()=> {
          this.cx.drawImage(background, 0, 0, window.innerWidth, window.innerHeight);
          for (let i = 0; i < this.canvasHistory.length; i++) {

            this.cx.lineWidth = this.canvasHistory[i]['lineWidth'];
            this.cx.lineCap = this.canvasHistory[i]['lineCap'];
            this.cx.strokeStyle = this.canvasHistory[i]['strokeStyle'];
            this.drawOnCanvas(this.canvasHistory[i]['origin'], this.canvasHistory[i]['final']);

          }
        }

      }

      reader.readAsText(file);
    } else {
      alert("Please select a .scribar file");
    }

  }



}
