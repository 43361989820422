import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-view-step-performance-data',
  templateUrl: './company-dashboard-view-step-performance-data.component.html',
  styleUrls: ['./company-dashboard-view-step-performance-data.component.css']
})

//Page to view step performance data
export class CompanyDashboardViewStepPerformanceDataComponent implements OnInit {

    handPositionAssessmentRecord:any;
    handMuslceAssessmentRecord:any;
    brainConcentrationAssessmentRecord:any;
    psychomotorAssessmentRecord:any;



public lineChartData:Array<any> = [
    {data: [], label: 'X Axis'},
    {data: [], label: 'Y Axis'},
    {data: [], label: 'Z Axis'}
  ];


  public lineChartLabels:Array<any> = [];


public lineChartData2:Array<any> = [
    {data: [], label: 'X Axis'},
    {data: [], label: 'Y Axis'},
    {data: [], label: 'Z Axis'}
  ];


  public lineChartLabels2:Array<any> = [];


public lineChartData3:Array<any> = [
    {data: [], label: 'Amplitude'}
  ];


  public lineChartLabels3:Array<any> = [];


public lineChartData4:Array<any> = [
    {data: [], label: 'Amplitude'}
  ];


  public lineChartLabels4:Array<any> = [];


  constructor(public authService: AuthService, private http: HttpClient, private router: Router,  private route: ActivatedRoute) {
console.log(route.snapshot.params.step_id);

    if("performance_record_id" in this.route.snapshot.params && "step_id" in this.route.snapshot.params){

    var that = this;


             this.http.get('https://scribar.herokuapp.com/api/performance/get/record?performance_record_id=' + this.route.snapshot.params.performance_record_id, this.authService.httpOptions)
                                        .subscribe(
                                        (data) => {

                                            console.log(data);

                                                data['psychomotorAssessmentRecords'].forEach(function(item) {
                                                  console.log("comparing " + item.step_id + " to " + route.snapshot.params.step_id);
                                                  if(item.step_id+"" === route.snapshot.params.step_id+""){
                                                    console.log("found match...");
                                                    console.log(item);
                                                    that.psychomotorAssessmentRecord = item;
                                                  }
                                                });

                                                data['handPositionAssessmentRecords'].forEach(function(item) {
                                                  console.log(item);
                                                  if(item.step_id+"" === route.snapshot.params.step_id+""){
                                                    console.log(item);
                                                    that.handPositionAssessmentRecord = item;

                                                    var str = (item.accelerometer_data+"").replace(new RegExp('%', 'g'), '"');
                                                    console.log(str);
                                                    var accel_data = JSON.parse(str);

                                                    accel_data.forEach(function(item) {
                                                    console.log(item);
                                                    that.lineChartData[0].data.push(item.x);
                                                    that.lineChartData[1].data.push(item.y);
                                                    that.lineChartData[2].data.push(item.z);
                                                    that.lineChartLabels.push(item.time);

                                                });

                                                str = (item.gyroscope_data+"").replace(new RegExp('%', 'g'), '"');
                                                    console.log(str);
                                                    var gyro_data = JSON.parse(str);

                                                    gyro_data.forEach(function(item) {
                                                    console.log(item);
                                                    that.lineChartData2[0].data.push(item.x);
                                                    that.lineChartData2[1].data.push(item.y);
                                                    that.lineChartData2[2].data.push(item.z);
                                                    that.lineChartLabels2.push(item.time);

                                                    });

                                                    }
                                                });

                                                  data['handMuslceAssessmentRecords'].forEach(function(item) {
                                                                                                      console.log(item);

                                                  if(item.step_id+"" === route.snapshot.params.step_id+""){
                                                    console.log(item);
                                                    that.handMuslceAssessmentRecord = item;

                                                   var str = (item.electromyograph_data+"").replace(new RegExp('%', 'g'), '"');
                                                    console.log(str);
                                                    var emg_data = JSON.parse(str);

                                                    emg_data.time_series.forEach(function(item) {
                                                    console.log(item);

                                                    that.lineChartData3[0].data.push(item.amplitude);
                                                    that.lineChartLabels3.push(item.time);

                                                    });

                                                  }
                                                });

                                                  data['brainConcentrationAssessmentRecords'].forEach(function(item) {
                                                                                                      console.log(item);

                                                  if(item.step_id+"" === route.snapshot.params.step_id+""){
                                                    console.log(item);
                                                    that.brainConcentrationAssessmentRecord = item;

                                                    var str = (item.cerebral_cortex_data+"").replace(new RegExp('%', 'g'), '"');
                                                    console.log(str);
                                                    var nirs_data = JSON.parse(str);

                                                    nirs_data.time_series.forEach(function(item) {
                                                    console.log(item);

                                                    that.lineChartData4[0].data.push(item.amplitude);
                                                    that.lineChartLabels4.push(item.time);

                                                    });
                                                  }
                                                });



                                        },
                                        (error) => {

                                            console.log(error);


                                            });
    }else{

        this.router.navigate(['/d/organization']);

    }

 }

  ngOnInit() {
  }


 public lineChartOptions:any = {
    responsive: true
  };
  public lineChartColors:Array<any> = [
    { // grey
      backgroundColor: 'rgba(249,60,60,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(0,154,127,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba((0,119,181, 0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColors2:Array<any> = [
    { // grey
      backgroundColor: 'rgba(18,212,0,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(0,119,181,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba((249,60,60, 0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColors3:Array<any> = [
    { // grey
      backgroundColor: 'rgba(236,48,147,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(0,154,127,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba((0,119,181, 0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

 public lineChartColors4:Array<any> = [
    { // grey
      backgroundColor: 'rgba(123,204,194,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(0,154,127,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba((0,119,181, 0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartLegend:boolean = true;
  public lineChartType:string = 'line';

  public randomize():void {
    let _lineChartData:Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = {data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label};
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }

  // events
  public chartClicked(e:any):void {
    console.log(e);
  }

  public chartHovered(e:any):void {
    console.log(e);}

}
