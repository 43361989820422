import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';

import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ConfirmResetPasswordComponent } from './auth/confirm-reset-password/confirm-reset-password.component';
import { CompanyDashboardComponent } from './dashboard/company/company-dashboard/company-dashboard.component';
import { CompanyDashboardRequestsComponent } from './dashboard/company/company-dashboard-requests/company-dashboard-requests.component';
import { CompanyDashboardManageEmployeesComponent } from './dashboard/company/company-dashboard-manage-employees/company-dashboard-manage-employees.component';
import { CompanyDashboardWorkflowsComponent } from './dashboard/company/company-dashboard-workflows/company-dashboard-workflows.component';
import { UserDashboardComponent } from './dashboard/user/user-dashboard/user-dashboard.component';
import { CompanyDashboardEditWorkflowComponent } from './dashboard/company/company-dashboard-edit-workflow/company-dashboard-edit-workflow.component';
import { CompanyDashboardViewWorkflowComponent } from './dashboard/company/company-dashboard-view-workflow/company-dashboard-view-workflow.component';
import { CompanyDashboardCreateWorkflowComponent } from './dashboard/company/company-dashboard-create-workflow/company-dashboard-create-workflow.component';
import { CompanyDashboardViewTaskComponent } from './dashboard/company/company-dashboard-view-task/company-dashboard-view-task.component';
import { CompanyDashboardEditTaskComponent } from './dashboard/company/company-dashboard-edit-task/company-dashboard-edit-task.component';
import { CompanyDashboardCreateTaskComponent } from './dashboard/company/company-dashboard-create-task/company-dashboard-create-task.component';
import { AdminDashboardComponent } from './dashboard/admin/admin-dashboard/admin-dashboard.component';
import { CompanyDashboardGroupsComponent } from './dashboard/company/company-dashboard-groups/company-dashboard-groups.component';
import { CompanyDashboardCreateGroupComponent } from './dashboard/company/company-dashboard-create-group/company-dashboard-create-group.component';
import { CompanyDashboardEditGroupComponent } from './dashboard/company/company-dashboard-edit-group/company-dashboard-edit-group.component';
import { CompanyDashboardViewGroupComponent } from './dashboard/company/company-dashboard-view-group/company-dashboard-view-group.component';
import { CompanyDashboardViewProfileComponent } from './dashboard/company/company-dashboard-view-profile/company-dashboard-view-profile.component';
import { CompanyDashboardEditProfileComponent } from './dashboard/company/company-dashboard-edit-profile/company-dashboard-edit-profile.component';
import { CompanyDashboardCreateProfileComponent } from './dashboard/company/company-dashboard-create-profile/company-dashboard-create-profile.component';
import { CompanyDashboardAddPaymentInfoComponent } from './dashboard/company/company-dashboard-add-payment-info/company-dashboard-add-payment-info.component';
import { CompanyDashboardViewDevicesComponent } from './dashboard/company/company-dashboard-view-devices/company-dashboard-view-devices.component';
import { CompanyDashboardViewPerformanceRecordsComponent } from './dashboard/company/company-dashboard-view-performance-records/company-dashboard-view-performance-records.component';
import { CompanyDashboardViewPerformanceRecordComponent } from './dashboard/company/company-dashboard-view-performance-record/company-dashboard-view-performance-record.component';
import { CompanyDashboardViewStepPerformanceDataComponent } from './dashboard/company/company-dashboard-view-step-performance-data/company-dashboard-view-step-performance-data.component';
import { CompanyViewTaskPerformanceRecordComponent } from './dashboard/company/company-view-task-performance-record/company-view-task-performance-record.component';
import { CompanyDashboardCreateProfileSelfComponent } from './dashboard/company/company-dashboard-create-profile-self/company-dashboard-create-profile-self.component';

import { ViewRequestsComponent } from './dashboard/company/view-requests/view-requests.component';

import { CompanyViewAccountComponent } from './dashboard/company/company-view-account/company-view-account.component';
import { CompanyEditAccountComponent } from './dashboard/company/company-edit-account/company-edit-account.component';
import { UserEditAccountComponent } from './dashboard/user/user-edit-account/user-edit-account.component';
import { UserViewAccountComponent } from './dashboard/user/user-view-account/user-view-account.component';
import { CompanyDashboardAddOverlayComponent } from './dashboard/company/company-dashboard-add-overlay/company-dashboard-add-overlay.component';
import { InstructorDashboardMainComponent } from './dashboard/instructor/instructor-dashboard-main/instructor-dashboard-main.component';
import { InstructorDashboardLiveComponent } from './dashboard/instructor/instructor-dashboard-live/instructor-dashboard-live.component';

import { CompanyDashboardSelectPlanComponent } from './dashboard/company/company-dashboard-select-plan/company-dashboard-select-plan.component';
import { CompanyDashboardEditPhotoComponent } from './dashboard/company/company-dashboard-edit-photo/company-dashboard-edit-photo.component';
import { ReportComponent } from './dashboard/traffic_stop/report/report.component';


const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
  {
    path: 'edit/photo/:url',
    component: CompanyDashboardEditPhotoComponent
  },

   {
        path: 'login/:isRedirect/:type',
        component: LoginComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'signup',
        component: SignupComponent
    },
    {
        path: 'forgot/password',
        component: ForgotPasswordComponent
    },
    {
        path: 'add/registered/object/:step_id',
        component: CompanyDashboardAddOverlayComponent
    },
    {
        path: 'confirm/reset/password/:email/:code',
        component: ConfirmResetPasswordComponent
    },
    {
        path: 'd/admin',
        canActivate: [AuthGuardService],
        component: AdminDashboardComponent
    },
    {
      path: 'd/organization/select-plan',
      canActivate: [AuthGuardService],
      component: CompanyDashboardSelectPlanComponent
    },
    {
        path: 'd/organization/account',
        canActivate: [AuthGuardService],
        component: CompanyViewAccountComponent
    },
    {
        path: 'd/organization/edit/account',
        canActivate: [AuthGuardService],
        component: CompanyEditAccountComponent
    },
    {
        path: 'd/user/account',
        canActivate: [AuthGuardService],
        component: UserViewAccountComponent
    },
    {
        path: 'd/user/edit/account',
        canActivate: [AuthGuardService],
        component: UserEditAccountComponent
    },
    {
        path: 'd/i/home',
        canActivate: [AuthGuardService],
        component: InstructorDashboardMainComponent
    },
    {
        path: 'd/i/live',
        canActivate: [AuthGuardService],
        component: InstructorDashboardLiveComponent
    },
    {
        path: 'd/organization',
        canActivate: [AuthGuardService],
        component: CompanyDashboardComponent
    },
    {
        path: 'd/organization/add-payment',
        canActivate: [AuthGuardService],
        component: CompanyDashboardAddPaymentInfoComponent
    },

     {
        path: 'd/organization/requests',
        canActivate: [AuthGuardService],
        component: CompanyDashboardRequestsComponent
    },

    {
        path: 'd/organization/workflows',
        canActivate: [AuthGuardService],
        component: CompanyDashboardWorkflowsComponent
    },
 {
        path: 'd/organization/devices',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewDevicesComponent
    },
    {
      path: 'live',
      component: CompanyDashboardViewDevicesComponent
    },
    {
        path: 'd/traffic_stop/report',
        canActivate: [AuthGuardService],
        component: ReportComponent
    },
    {
        path: 'd/organization/view/workflow/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewWorkflowComponent
    },
    {
        path: 'd/organization/edit/workflow/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardEditWorkflowComponent
    },
    {
        path: 'd/organization/create/workflow',
        canActivate: [AuthGuardService],
        component: CompanyDashboardCreateWorkflowComponent
    },
    {
        path: 'd/organization/edit/task/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardEditTaskComponent
    },
    {
        path: 'd/organization/view/task/:workflow_id/:task_id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewTaskComponent
    },
    {
        path: 'd/organization/create/task/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardCreateTaskComponent
    },
    {
        path: 'd/organization/groups',
        canActivate: [AuthGuardService],
        component: CompanyDashboardGroupsComponent
    },
    {
        path: 'd/organization/create/group',
        canActivate: [AuthGuardService],
        component: CompanyDashboardCreateGroupComponent
    },
    {
        path: 'd/organization/view/group/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewGroupComponent
    },
    {
        path: 'd/organization/edit/group/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardEditGroupComponent
    },
    {
        path: 'd/organization/view/profile/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewProfileComponent
    },
    {
        path: 'd/organization/edit/profile/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardEditProfileComponent
    },
    {
        path: 'd/organization/create/profile/:id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardCreateProfileComponent
    },
    {
        path: 'c/prof/s',
        component: CompanyDashboardCreateProfileSelfComponent
    },
    {
        path: 'd/organization/view/performance/records/:performance_profile_id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewPerformanceRecordsComponent
    },
    {
        path: 'd/organization/view/performance/record/:performance_record_id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewPerformanceRecordComponent
    },
    {
        path: 'd/organization/view/performance/record/step/:performance_record_id/:step_id',
        canActivate: [AuthGuardService],
        component: CompanyDashboardViewStepPerformanceDataComponent
    },
    {
        path: 'd/organization/view/task/performance/record/:performance_profile_id/:task_id',
        canActivate: [AuthGuardService],
        component: CompanyViewTaskPerformanceRecordComponent
    },
     {
        path: 'd/organization/manage/employees',
        canActivate: [AuthGuardService],
        component: CompanyDashboardManageEmployeesComponent
    },
    {
        path: 'd/user',
        canActivate: [AuthGuardService],
        component: UserDashboardComponent
    },{
        path: 'd/admin',
        canActivate: [AuthGuardService],
        component: AdminDashboardComponent
    },
    { path: '**',
      redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
