import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import {MatTableDataSource} from '@angular/material';
import {ConfirmDialog} from '../confirm-dialog/confirm.component';
import {HelpDialog} from '../help-dialog/help.component';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-workflows',
  templateUrl: './company-dashboard-workflows.component.html',
  styleUrls: ['./company-dashboard-workflows.component.scss']
})
export class CompanyDashboardWorkflowsComponent implements OnInit {

  public workflows:any[];
  public search_string: string = "";
  public search_term: string = "";
  public isFullyloaded = false;
  displayedColumns = ['workflow_name', 'createdAt', 'actions'];
  dataSource = new MatTableDataSource([]);
  loadingData = true;

  constructor(public dialog: MatDialog, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService) {

    if(this.authService.userProfile['type'] === this.authService.TYPE_GENERAL_USER){

    }

     if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
       this.router.navigate(['/d/admin']);
    }



//Observable for when authentication status has been retrieved
        this.authService.everyThingReady.subscribe(
        (data) => {

               this.http.get('https://scribar.herokuapp.com/api/dashboard/get/workflows?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting workflows...");
                                        console.log(data);
                                        this.workflows = <any[]>data;
                                        this.dataSource = new MatTableDataSource(this.workflows);
                                        console.log(this.workflows);
                                        this.isFullyloaded = true;
                                        this.loadingData = false;
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

        }
      );




    }

  openWorkflow(id){
    this.router.navigate(['/d/organization/view/workflow', id]);
  }

   showHelp(title, content){
        let dialogRef = this.dialog.open(HelpDialog, {
          width: '500px',
          data: {title:title, content:content}
        });
    }


 removeWorkflow(id){

console.log("Remove workflow!!");
let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(confirm => {

        if(confirm){

            this.http.get('https://scribar.herokuapp.com/api/dashboard/remove/workflow?workflow_id=' + id, this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

    this.http.get('https://scribar.herokuapp.com/api/dashboard/get/workflows?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(

                                    (data) => {

                                        console.log("Getting workflows...");
                                        console.log(data);
                                        this.workflows = <any[]>data;
                                        console.log(this.workflows);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });





                                    },
                                    (error) => {


                                        });


        }else{

        }

    });

  }




  ngOnInit() {
  }


}

