import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ENTER, COMMA} from '@angular/cdk/keycodes';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'routes-dialog',
  templateUrl: './routes.html',
  styleUrls: ['./routes.css']
})
export class RoutesDialog {


    public newRoute:any = {};
    public shownStepInModal: any;

    constructor(
   
    public dialogRef: MatDialogRef<RoutesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

        console.log(data);
        this.shownStepInModal = data['step'];

        this.newRoute['results'] = [];
        this.newRoute['condition_indexes'] = [];
        
        
        for(var i = 0; i < this.shownStepInModal.conditions.length; i++){
            this.newRoute.results.push('YES');
        }

         for(var i = 0; i < this.data['length']; i++){
            this.newRoute.condition_indexes.push(i);
        }

        console.log(this.newRoute);

        this.newRoute['step_index'] = 0;
        

    }


    addNewRoute(list){

        var result_string = "";

        console.log(this.newRoute.results);

        for(var i = 0; i < this.newRoute.results.length;i++){
            if(this.newRoute.results[i] === "1"){
                result_string += "T";
            }
            if(this.newRoute.results[i] === "2"){
                result_string += "F";
            }

        }
        
        console.log(result_string);

        list.push({condition_result:result_string, result_step_index:this.newRoute.step_index, step_id:this.shownStepInModal['step']['step_id']});
        console.log(list);


    }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
