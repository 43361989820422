import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-dashboard-edit-group',
  templateUrl: './company-dashboard-edit-group.component.html',
  styleUrls: ['./company-dashboard-edit-group.component.scss']
})


//PAGE TO EDIT A GROUP
export class CompanyDashboardEditGroupComponent implements OnInit {

    public group:any;
    public profiles:any[];
    public workflows:any[];

postButtonText = "Save";
  hasError = false;
  errorText = "Error updating group";

  constructor(public location: Location, public authService: AuthService, private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute) {


    //Check for group id param in url
    if("id" in this.route.snapshot.params){

      //Get parent workflow from server
       this.http.get('https://scribar.herokuapp.com/api/dashboard/get/workflows?company_id=' + this.authService.userProfile['c']['company_id'], this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting workflows...");
                                        console.log(data);
                                        this.workflows = <any[]>data;

                                        if(this.workflows.length > 0){
                                        }

                                    },
                                    (error) => {

                                        console.log(error);


                                        });

              //Get group from server
              this.http.get('https://scribar.herokuapp.com/api/profile/get/group?group_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting group by id " + this.route.snapshot.params.id + "...");
                                        this.group = data;
                                        console.log(this.group);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

           //Get all profiles from group from server
          this.http.get('https://scribar.herokuapp.com/api/profile/get/profiles/by/group?group_id=' + this.route.snapshot.params.id, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        console.log("Getting profiles by group_id " + this.route.snapshot.params.id + "...");
                                        this.profiles = <any[]>data;
                                        console.log(this.profiles);
                                    },
                                    (error) => {

                                        console.log(error);


                                        });

    }else{
        //If no id in params navigate to home page
        this.router.navigate(['/d/organization']);

    }



    }


//Save changes to group
saveGroup(){
   this.postButtonText = "Loading...";

                   //Send updated data to server
                  this.http.post('https://scribar.herokuapp.com/api/profile/update/group', this.group, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                                                                this.postButtonText = "Save";

                                     //if success navigate back to groups page
                                        this.router.navigate(['/d/organization/groups']);

                                    },
                                    (error) => {


                                        //if error show appropraite prompts
                                        console.log(error);
                                        console.log(error);
                                        this.postButtonText = "Save";
                                        this.hasError = true;
                                        if(error.status === 409){
                                        this.errorText = "Error updating group.";
                                        }else{
                                        this.errorText = "Error updating group"
                                        }


                                        });
}


  ngOnInit() {
  }

}
