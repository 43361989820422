import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-view-account',
  templateUrl: './user-view-account.component.html',
  styleUrls: ['./user-view-account.component.css']
})
export class UserViewAccountComponent implements OnInit {

    constructor(public authService: AuthService, private http: HttpClient, private router: Router, private route: ActivatedRoute) { 

    if(this.authService.userProfile['type'] === this.authService.TYPE_JUXTOPIA_ADMIN){
       this.router.navigate(['/d/admin']);
    }

    if(this.authService.userProfile['type'] === this.authService.TYPE_COMPANY){
       this.router.navigate(['/login']);
    }

    console.log(this.authService.userProfile);


    }

  ngOnInit() {
  }

}
